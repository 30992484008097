/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  TextBoxWidget,
  DropDownWidget,
  CommandContainerWidget,
  ButtonWidget,
  CheckboxWidget,
  TextAreaWidget,
  DateWidget,
  setValue,
  getValue,
  setData,
  getData,
  goTo,
  hide,
  show
} from "../../shared/WindowImports";

import "./OpenAdjustmentEntry.scss";

// START_USER_CODE-USER_IMPORTS
import ContractManagement_header from "../../Header/Header";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService"
import { SystemMaintenanceApplicationSupportService } from "../Service/SystemMaintenanceApplicationSupportService";
import moment, { isDate } from "moment";
import StripPic from "../../../../assets/img/PinStrip.png";
// END_USER_CODE-USER_IMPORTS
function SystemMaintenanceApplicationSupport_OpenAdjustmentEntry(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  // START_USER_CODE-USER_PROPERTIES

  // END_USER_CODE-USER_PROPERTIES

  let states = {
    Label: "OpenAdjustmentEntry",
    windowName: "OpenAdjustmentEntry",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "SystemMaintenanceApplicationSupport.OpenAdjustmentEntry",
    // START_USER_CODE-USER_OpenAdjustmentEntry_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Open Adjustment Entry",
      scrCode: "PN0160D",
    },
    // END_USER_CODE-USER_OpenAdjustmentEntry_PROPERTIES
    btnAdd: {
      name: "btnAdd",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Add",
      CharWidth: "10",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnAdd_PROPERTIES

      // END_USER_CODE-USER_btnAdd_PROPERTIES
    },
    btnCancel: {
      name: "btnCancel",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Cancel",
      CharWidth: "15",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnCancel_PROPERTIES

      // END_USER_CODE-USER_btnCancel_PROPERTIES
    },
    btnExit: {
      name: "btnExit",
      type: "ButtonWidget",
      parent: "cmmndCntnrActions",
      Label: "Exit",
      CharWidth: "11",

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_btnExit_PROPERTIES

      // END_USER_CODE-USER_btnExit_PROPERTIES
    },
    chkboxCreatePayables: {
      name: "chkboxCreatePayables",
      type: "CheckBoxWidget",
      parent: "grpbxOpenAdjustmentEntry",
      Label: "Create payables to 3rd party vendors. (Only if applicable!)",
      HasLabel: false,

      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxCreatePayables_PROPERTIES

      // END_USER_CODE-USER_chkboxCreatePayables_PROPERTIES
    },
    colAdjustmentAmount: {
      name: "colAdjustmentAmount",
      type: "TextBoxWidget",
      parent: "grpbxOpenAdjustmentEntry",
      Label: "Adjustment Amount:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_colAdjustmentAmount_PROPERTIES

      // END_USER_CODE-USER_colAdjustmentAmount_PROPERTIES
    },
    ddReceivablesType: {
      name: "ddReceivablesType",
      type: "DropDownFieldWidget",
      parent: "grpbxOpenAdjustmentEntry",
      Label: "Receivables Type:",
      DataProviderForDropDown: "inline",

      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_ddReceivablesType_PROPERTIES

      // END_USER_CODE-USER_ddReceivablesType_PROPERTIES
    },
    txtarComments: {
      name: "txtarComments",
      type: "TextAreaWidget",
      parent: "grpbxOpenAdjustmentEntry",
      Label: "Comments:",
      LengthRange: { MinLength: 0, MaxLength: 256 },
      ofTypeDomain: "d_String",
      // START_USER_CODE-USER_txtarComments_PROPERTIES

      // END_USER_CODE-USER_txtarComments_PROPERTIES
    },
    txtEffectiveDate: {
      name: "txtEffectiveDate",
      type: "DateTimeWidget",
      parent: "grpbxOpenAdjustmentEntry",
      Label: "Effective Date:",
      LengthRange: { MinLength: 0, MaxLength: 16 },
      ofTypeDomain: "d_dateTime",
      // START_USER_CODE-USER_txtEffectiveDate_PROPERTIES

      // END_USER_CODE-USER_txtEffectiveDate_PROPERTIES
    },
    grpbxOpenAdjustmentEntry: {
      name: "grpbxOpenAdjustmentEntry",
      type: "GroupBoxWidget",
      parent: "OpenAdjustmentEntry",
      Height: "",
      Width: "",
      ColsForTabLandscape: "1",
      HasLabel: false,
      Cols: "1",
      ColsForTabPotrait: "1",
      ColsForLargeDesktop: "1",
      // START_USER_CODE-USER_grpbxOpenAdjustmentEntry_PROPERTIES

      // END_USER_CODE-USER_grpbxOpenAdjustmentEntry_PROPERTIES
    },
    cmmndCntnrActions: {
      name: "cmmndCntnrActions",
      type: "CommandContainerWidget",
      parent: "OpenAdjustmentEntry",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_cmmndCntnrActions_PROPERTIES

      // END_USER_CODE-USER_cmmndCntnrActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });
  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    // START_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS

    // END_USER_CODE-USER_COMPONENTDIDMOUNT-METHODS
    setValue(thisObj, "ddReceivablesType", '0')
    FormLoad()
  }, []);
  function FormLoad() {
    let openaccountprofiledetails = getData(thisObj,"openaccountprofiledata")
    setData(thisObj,"formLoadData",openaccountprofiledetails)
    bFillReceivableType();
    hide(thisObj, 'chkboxCreatePayables', false)
  }
  // START_USER_CODE-USER_METHODS
  const bFillReceivableType = () => {
    let js = []
    js.push({ key: '0', description: 'Receivable Type' })
    js.push({ key: 'A/R', description: 'A/R' })
    js.push({ key: 'S/R', description: 'S/R' })
    js.push({ key: 'C/R', description: 'C/R' })
    thisObj.setState(current => {
      return {
        ...current,
        ddReceivablesType: {
          ...state["ddReceivablesType"],
          valueList: js
        }
      }
    })
    setValue(thisObj,"ddReceivablesType",js.at(0).key)
  }
  const onddReceivablesTypeChange = async () => {
    try {
      let ddReceivablesType = getValue(thisObj, 'ddReceivablesType')
      if (ddReceivablesType == 'A/R') {
        setValue(thisObj, 'chkboxCreatePayables', true)
        show(thisObj, 'chkboxCreatePayables')
      } else {
        setValue(thisObj, 'chkboxCreatePayables', false)
        hide(thisObj, 'chkboxCreatePayables')
      }
    }
    catch (err) {
    }
  };
  thisObj.onddReceivablesTypeChange = onddReceivablesTypeChange;
  function isValidDate(date) {
    let dateObj = new Date(date);
    if (dateObj instanceof Date) {
      return true;
    } else {
      return false;
    }
  }
  function ConvertToDate(str) {
    if (str !== undefined && str !== '') {
      let date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [mnth, day, date.getFullYear()].join("/");
    } else {
      return '';
    }
  }
  let bFormValid = async () => {
    let isformValid = true
    let txtEffectiveDate = ConvertToDate(getValue(thisObj, 'txtEffectiveDate'))
    if (!isValidDate(txtEffectiveDate) || txtEffectiveDate == undefined || txtEffectiveDate == null || txtEffectiveDate == '') {
      isformValid = false;
      showMessage(thisObj, 'Invalid Date!!!')
      return false;
    } else {
      if (new Date() < new Date(txtEffectiveDate)) {
        if (process.env.REACT_APP_ENVIR == 'LOCAL' || process.env.REACT_APP_ENVIR == 'DEV' || process.env.REACT_APP_ENVIR == 'TEST' || process.env.REACT_APP_ENVIR == 'UAT') {
          // pps_defaults API Wil get call
          let ppsDefault = await ContractManagementService.RetrievePeanutStaticValues();
          if (ppsDefault !== undefined && ppsDefault.length > 0) {
            let compId = sessionStorage.getItem('compId');
            let key = `${compId}` + '_default_overrides';
            if (ppsDefault[0].pps_defaults.hasOwnProperty(key) == true) {
              let value = Object.getOwnPropertyDescriptor(ppsDefault[0].bypass_curr_date_edit, key).value;
              if (value !== true) {
                isformValid = false;
                showMessage(thisObj, "Date cannot be in future!!!", false);
                return false;
              }
            }
          }
        }
      }
    }
    if (getValue(thisObj, "ddReceivablesType") == "0") {
      isformValid = false;
      showMessage(thisObj, "Receivable Type is a required field.", false);
      return false
    }
    if (getValue(thisObj, "colAdjustmentAmount") == undefined || getValue(thisObj, "colAdjustmentAmount") == null || getValue(thisObj, "colAdjustmentAmount") == '') {
      showMessage(thisObj, "Adjustment Amount is a required field.", false);
      isformValid = false;
      return false
    }
    else if(isNaN(getValue(thisObj,"colAdjustmentAmount"))){
      showMessage(thisObj, "Adjustment Amount is a numeric field.", false);
      isformValid = false;
      return false
    }
    if (getValue(thisObj, "txtarComments") == undefined || getValue(thisObj, "txtarComments") == null || getValue(thisObj, "txtarComments") == '') {
      showMessage(thisObj, "Comments is a required field.");
      isformValid = false;
      return false
    }
    return isformValid;
  }
  const oncolAdjustmentAmountChange = () => {
    let adjamntValue = getValue(thisObj, 'colAdjustmentAmount')
    setValue(thisObj, "colAdjustmentAmount", validateTextBox(adjamntValue))
  };
  thisObj.oncolAdjustmentAmountChange = oncolAdjustmentAmountChange
  // Validation Of Adjustment TextBox
  const validateTextBox = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) >= 48 && data[i].charCodeAt(0) <= 57) || (data[i].charCodeAt(0) == 46)|| (data[i].charCodeAt(0) == 8) || (res.indexOf(".") == -1 && data[i].charCodeAt(0) == 46) || (res.indexOf("-") == -1 && data[i].charCodeAt(0) == 45)) {
        res += data[i]
      }
    }
    return res;
  }
  const ontxtarCommentsChange = () => {
    let comments = getValue(thisObj, 'txtarComments')
    setValue(thisObj, "txtarComments", validateTextBox2(comments))
  };
  thisObj.ontxtarCommentsChange = ontxtarCommentsChange
  const validateTextBox2 = (data) => {
    var res = ''
    for (var i = 0; i < data.length; i++) {
      if ((data[i].charCodeAt(0) !=38 && data[i].charCodeAt(0) !=39)) {
        res += data[i]
      }
    }
    return res;
  }
  //Cancel Button
  const onbtnCancelClick = () => {
    setValue(thisObj,"colAdjustmentAmount","")
    setValue(thisObj,"ddReceivablesType","")
    setValue(thisObj,"txtEffectiveDate","")
    setValue(thisObj,"txtarComments","")
    setValue(thisObj,"ddReceivablesType",'0')
    hide(thisObj, 'chkboxCreatePayables',false)
  };
  thisObj.onbtnCancelClick = onbtnCancelClick;
  //Add Button Functionality
  const onbtnAddClick = async () => {
    if (!await bFormValid()) {
      return false;
    }
    let sCreatePay3rd="Y"
    if(getValue(thisObj, "chkboxCreatePayables")==false)
    {
        sCreatePay3rd="N"
    }
    let formLoadData=getData(thisObj,"formLoadData")
    let buy_pt_id = formLoadData.buyingpointid
    let acct_vendor = formLoadData.acctvendor
    let acct_remit = formLoadData.acctremit
    let dataObj = {
      "acct_remit":acct_remit,
      "adjustment_date_time": getValue(thisObj, "txtEffectiveDate"),
      "adjustment_amt": getValue(thisObj, "colAdjustmentAmount"),
      "adjustment_acct": getValue(thisObj, "ddReceivablesType"),
     //added and commented - PRB0054045/INC5910850 - 8thJan '2025
     // "comments":  getValue(thisObj, "txtarComments"),
      "comments":  Buffer.from(getValue(thisObj, "txtarComments")).toString('base64'),
      //end of changes
      "payee_enable_ind": sCreatePay3rd
    }
    let data1 = {
      'buy_pt_id': buy_pt_id,
      'acct_vendor': acct_vendor,
      }
    let response = await SystemMaintenanceApplicationSupportService.CreateOpenAccountAdjustment(buy_pt_id,acct_vendor,dataObj)
    let data=response
    if (data.status == 404 || data.status == 500 || data.status == null || data.status == '') {
      showMessage(thisObj, "An error occured while updating the database.  Please verify that your data is correct and retry.  Contact your system administrator if this problem persists." + response.message)
      return;
    };
    setValue(thisObj,"colAdjustmentAmount","")
    setValue(thisObj,"ddReceivablesType","")
    setValue(thisObj,"txtEffectiveDate","")
    setValue(thisObj,"txtarComments","")
    setValue(thisObj,"ddReceivablesType",'0')
    hide(thisObj, 'chkboxCreatePayables',false)
    setData(thisObj,'closingOfOpenAdjustment',data1);
  };
  thisObj.onbtnAddClick = onbtnAddClick;
  const oncolAdjustmentAmountBlur = (e)=>{
    if(e.target.value!=""){
      setValue(thisObj, "colAdjustmentAmount",Number(e.target.value).toFixed(2))
    }
  };
  thisObj.oncolAdjustmentAmountBlur = oncolAdjustmentAmountBlur;
  //Exit button
  const onbtnExitClick = () => {
    try {
      document.getElementById("SystemMaintenanceApplicationSupport_OpenAdjustmentEntryPopUp").childNodes[0].click()
    } catch (err) {
      if (err instanceof EvalError) {
        showMessage(thisObj, err.message);
      } else {
        showMessage(
          thisObj,
          "Exception in PreSubmit Event code for widget:btnExit event:Click"
        );
      }
      return false;
    }
    return true;
  };
  thisObj.onbtnExitClick = onbtnExitClick;
  // END_USER_CODE-USER_METHODS
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            {/* START_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <ContractManagement_header headerData={state.headerData} />
            {/* END_USER_CODE-USER_BEFORE_PAGE_HEADER*/}
            <h1 className="pageHeader">{state.Label}</h1>
            {/* START_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            {/* END_USER_CODE-USER_AFTER_PAGE_HEADER*/}

            <Form noValidate className="row">
              {/* START_USER_CODE-USER_BEFORE_OpenAdjustmentEntry*/}

              {/* END_USER_CODE-USER_BEFORE_OpenAdjustmentEntry*/}

              {/* START_USER_CODE-USER_BEFORE_grpbxOpenAdjustmentEntry*/}

              {/* END_USER_CODE-USER_BEFORE_grpbxOpenAdjustmentEntry*/}

              <GroupBoxWidget
                conf={state.grpbxOpenAdjustmentEntry}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_txtEffectiveDate*/}

                {/* END_USER_CODE-USER_BEFORE_txtEffectiveDate*/}
                <DateWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtEffectiveDate}
                  screenConf={state}
                ></DateWidget>
                {/* START_USER_CODE-USER_AFTER_txtEffectiveDate*/}

                {/* END_USER_CODE-USER_AFTER_txtEffectiveDate*/}
                {/* START_USER_CODE-USER_BEFORE_ddReceivablesType*/}

                {/* END_USER_CODE-USER_BEFORE_ddReceivablesType*/}

                <DropDownWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.ddReceivablesType}
                  screenConf={state}
                ></DropDownWidget>
                {/* START_USER_CODE-USER_AFTER_ddReceivablesType*/}

                {/* END_USER_CODE-USER_AFTER_ddReceivablesType*/}
                {/* START_USER_CODE-USER_BEFORE_chkboxCreatePayables*/}

                {/* END_USER_CODE-USER_BEFORE_chkboxCreatePayables*/}

                <CheckboxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxCreatePayables}
                  screenConf={state}
                ></CheckboxWidget>
                {/* START_USER_CODE-USER_AFTER_chkboxCreatePayables*/}

                {/* END_USER_CODE-USER_AFTER_chkboxCreatePayables*/}
                {/* START_USER_CODE-USER_BEFORE_colAdjustmentAmount*/}

                {/* END_USER_CODE-USER_BEFORE_colAdjustmentAmount*/}

                <TextBoxWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.colAdjustmentAmount}
                  screenConf={state}
                  onPaste={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></TextBoxWidget>
                {/* START_USER_CODE-USER_AFTER_colAdjustmentAmount*/}

                {/* END_USER_CODE-USER_AFTER_colAdjustmentAmount*/}
                {/* START_USER_CODE-USER_BEFORE_txtarComments*/}

                {/* END_USER_CODE-USER_BEFORE_txtarComments*/}

                <TextAreaWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.txtarComments}
                  screenConf={state}
                ></TextAreaWidget>
                {/* START_USER_CODE-USER_AFTER_txtarComments*/}

                {/* END_USER_CODE-USER_AFTER_txtarComments*/}
              </GroupBoxWidget>
              {/* START_USER_CODE-USER_AFTER_grpbxOpenAdjustmentEntry*/}

              {/* END_USER_CODE-USER_AFTER_grpbxOpenAdjustmentEntry*/}
              {/* START_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_BEFORE_cmmndCntnrActions*/}
              <img
                src={StripPic}
                  className="stripDesign"
                />
              <CommandContainerWidget
                conf={state.cmmndCntnrActions}
                screenConf={state}
              >
                {/* START_USER_CODE-USER_BEFORE_btnAdd*/}

                {/* END_USER_CODE-USER_BEFORE_btnAdd*/}

                <ButtonWidget
                  conf={state.btnAdd}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnAdd*/}

                {/* END_USER_CODE-USER_AFTER_btnAdd*/}
                {/* START_USER_CODE-USER_BEFORE_btnCancel*/}

                {/* END_USER_CODE-USER_BEFORE_btnCancel*/}

                <ButtonWidget
                  conf={state.btnCancel}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnCancel*/}

                {/* END_USER_CODE-USER_AFTER_btnCancel*/}
                {/* START_USER_CODE-USER_BEFORE_btnExit*/}

                {/* END_USER_CODE-USER_BEFORE_btnExit*/}

                <ButtonWidget
                  conf={state.btnExit}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                {/* START_USER_CODE-USER_AFTER_btnExit*/}

                {/* END_USER_CODE-USER_AFTER_btnExit*/}
              </CommandContainerWidget>
              {/* START_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* END_USER_CODE-USER_AFTER_cmmndCntnrActions*/}

              {/* START_USER_CODE-USER_AFTER_OpenAdjustmentEntry*/}

              {/* END_USER_CODE-USER_AFTER_OpenAdjustmentEntry*/}
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(
  SystemMaintenanceApplicationSupport_OpenAdjustmentEntry
);
