/* eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  object,
  Formik,
  withRouter,
  invokeEventHandler,
  callOnOpenPreSubmit,
  callOnOpenService,
  handleModalHide,
  parentWindow,
  dataCopyObj,
  showMessage,
  switchToTab,
  useAppContext,
  useHistory,
  useLocation,
  useRouteMatch,
  Modal,
  _kaledo,
  initForm,
  GroupBoxWidget,
  ButtonWidget,
  CheckboxGroupWidget,
  setData,
  getData,
  hide
} from "../../shared/WindowImports";
import "./ReportPreviewTransfer.scss";
import ContractManagement_header from "../../Header/Header";
import { StockTransferService } from "../Service/StockTransferService";
import { SettlementService } from "../../Settlements/Service/SettlementService";
import { ContractManagementService } from "../../ContractManagement/Service/ContractManagementService";

function StockTransfer_ReportPreviewTransfer(props) {
  const contextType = useAppContext();
  const screenRef = useRef();
  //PRB0053955 - Stock transfer printing issue fixes
  const [isLoading, setLoading] = useState(true); 
  let strSettlement1007="";//added Nov 20 PRB0055004/INC6274591
  let states = {
    Label: "ReportPreviewTransfer",
    windowName: "ReportPreviewTransfer",
    template: "Standard Screen",
    Cols: "3",
    ColsForMobile: "3",
    ColsForTabLandscape: "3",
    ColsForTabPotrait: "3",
    is12Column: false,
    isResponsiveLayout: false,
    Height: "",
    Left: "",
    Top: "",
    Width: "",
    i18Key: "StockTransfer.ReportPreviewTransfer",
    // START_USER_CODE-USER_ReportPreviewTransfer_PROPERTIES
    horizontalForm: true,
    headerData: {
      scrName: "Print Preview",
      scrCode: "PN9000A",
    },
    // END_USER_CODE-USER_ReportPreviewTransfer_PROPERTIES
    btnClose: {
      name: "btnClose",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Close",
      CharWidth: "13",
      // START_USER_CODE-USER_btnClose_PROPERTIES

      // END_USER_CODE-USER_btnClose_PROPERTIES
    },
    btnPrint: {
      name: "btnPrint",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Print",
      CharWidth: "13",
      // START_USER_CODE-USER_btnPrint_PROPERTIES

      // END_USER_CODE-USER_btnPrint_PROPERTIES
    },
    btnPrintSetup: {
      name: "btnPrintSetup",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Print Setup",
      CharWidth: "24",
      // START_USER_CODE-USER_btnPrintSetup_PROPERTIES

      // END_USER_CODE-USER_btnPrintSetup_PROPERTIES
    },
    btnSaveAsPDF: {
      name: "btnSaveAsPDF",
      type: "ButtonWidget",
      parent: "grpbxActions",
      Label: "Save as PDF",
      CharWidth: "25",
      // START_USER_CODE-USER_btnSaveAsPDF_PROPERTIES

      // END_USER_CODE-USER_btnSaveAsPDF_PROPERTIES
    },
    chkboxSelectCopies: {
      name: "chkboxSelectCopies",
      type: "CheckBoxGroupFieldWidget",
      parent: "grpbxActions",
      Options: "Control Copy:1,Trucker Copy:2,File Copy:3",
      Label: "Select Copies",
      ColSpan: "1",
      ColSpanForLargeDesktop: "4",
      ColSpanForTabLandscape: "4",
      ColSpanForTabPotrait: "4",
      ColSpanForMobile: "4",
      ofTypeDomain: "d_boolean",
      // START_USER_CODE-USER_chkboxSelectCopies_PROPERTIES

      // END_USER_CODE-USER_chkboxSelectCopies_PROPERTIES
    },
    grpbxPreview: {
      name: "grpbxPreview",
      type: "GroupBoxWidget",
      parent: "ReportPreviewTransfer",
      Height: "",
      Width: "",
      HasLabel: false,
      // START_USER_CODE-USER_grpbxPreview_PROPERTIES

      // END_USER_CODE-USER_grpbxPreview_PROPERTIES
    },
    grpbxActions: {
      name: "grpbxActions",
      type: "GroupBoxWidget",
      parent: "ReportPreviewTransfer",
      Height: "",
      Width: "",
      ColsForMobile: "4",
      ColsForTabLandscape: "4",
      HasLabel: false,
      Cols: "5",
      ColsForTabPotrait: "4",
      ColsForLargeDesktop: "4",
      // START_USER_CODE-USER_grpbxActions_PROPERTIES

      // END_USER_CODE-USER_grpbxActions_PROPERTIES
    },
  };
  let _buttonServices = {};
  let _buttonNavigation = {};
  let _winServices = {};
  const [state, setState] = useState(states);
  const thisObj = {
    context: contextType,
    state: state,
    props: props,
    _buttonServices: _buttonServices,
    _buttonNavigation: _buttonNavigation,
    _winServices: _winServices,
    setState: setState,
  };
  useEffect(() => {
    parentWindow(thisObj);
  });

  useEffect(() => {
    callOnOpenPreSubmit(thisObj, state.windowName);
    callOnOpenService(thisObj, screenRef);
    hide(thisObj, 'btnPrintSetup');
    hide(thisObj, 'btnSaveAsPDF');
    hide(thisObj, 'chkboxSelectCopies');
    let reportTitle = getData(thisObj, 'ReportTitle');
    //PRB0053955 - Stock transfer printing issue fixes
    setLoading(true);
    if (reportTitle === 'BillOfLading') {
      RptBillOfLadingData();
    } else if (reportTitle === 'Settlement') {
      FASettlementData();
    }
  }, [getData(thisObj, 'ReportTitle')]);

  // Get API Data
  async function RptBillOfLadingData() {
    let reportPreviewTransferForRPTbill = getData(thisObj, 'ReportPreviewTransferForRPTbill');
    if (reportPreviewTransferForRPTbill !== null && reportPreviewTransferForRPTbill?.XFERStatus != 'Void') {
      await rptBillofLading(reportPreviewTransferForRPTbill.TransferNumber,
        reportPreviewTransferForRPTbill.ShpBuyPT,
        reportPreviewTransferForRPTbill.XFERStatus,
        reportPreviewTransferForRPTbill.TransferType,
        reportPreviewTransferForRPTbill.SCAC
      );
      //added 20Nov -PRB0055004/INC6274591
      strSettlement1007= reportPreviewTransferForRPTbill.settled_1007_num;
      setData(thisObj,'mStockTransfer',strSettlement1007);
      //end of changes
      await ReadFile();
      //PRB0053955 - Stock transfer printing issue fixes
      setLoading(false);
    }
  }

  async function rptBillofLading(sTransferNum, sShpBuyPt, sTransferStatus, sTransferType, SCAC) {
    setData(thisObj, 'mTransferNum', sTransferNum);
    setData(thisObj, 'mReportType', 'BillOfLading');
    setData(thisObj, 'msReport', 'BILL_OF_LADING');

    let objData = {
      CropYear: (JSON.parse(sessionStorage.getItem('year'))),
      mTransferStatus_SCAC: SCAC,
      ShippedDate: '',
      Received_Date: '',
      TicketNumber: '',
      TicketType: '',
      Location_Name_1: '',
      Location_Number_1: '',
      Location_Address_1: '',
      Location_Phone_1: '',
      Location_Name_2: '',
      Location_Number_2: '',
      Location_Address_2: '',
      Location_Phone_2: '',
      TruckLine: '',
      TrailerLicenseNumber: '',
      DispatchNumber: '',
      DriversSignature: '',
      FF_State: '',
      FF_County: '',
      FF_Farm: '',
      FF_StateDisplay: '',
      FF_CountyDisplay: '',
      FF_FarmDisplay: '',
      Peanut_Type_ID: '',
      Peanut_Type_Name: '',
      Shipped_Loaded_From_WarehouseNumber: '',
      Shipped_Loaded_From_BinNumber: '',
      Shipped_ScaleTicketNumber: '',
      Shipped_GrossWt_LBS: '',
      Shipped_TareWt_LBS: '',
      Shipped_NetWt_LBS: '',
      Shipped_Peanut_Variety_ID: '',
      Shipped_Peanut_Variety_Name: '',
      Shipped_Peanut_Gen: '',
      Shipped_Peanut_Seg: '',
      Shipped_Oleic_Ind: '',
      Shipped_Edible_Oil_Ind: '',
      Shipped_Split_Scale: '',
      Received_Loaded_To_WarehouseNumber: '',
      Received_Loaded_To_BinNumber: '',
      Received_ScaleTicketNumber: '',
      Received_GrossWt_LBS: '',
      Received_TareWt_LBS: '',
      Received_NetWt_LBS: '',
      Received_Peanut_Variety_ID: '',
      Received_Peanut_Variety_Name: '',
      Received_Peanut_Gen: '',
      Received_Peanut_Seg: '',
      Received_Oleic_Ind: '',
      Received_Edible_Oil_Ind: '',
      Received_Split_Scale: '',
      Received_WtVariance: '',
      Received_FM_LBS: '',
      Received_WeightLessFM_LBS: '',
      Received_ExcessMoisture_LBS: '',
      Received_Freight_Rate_Per_Ton: '',
      Received_FM_NetWt_LBS: '',
      Received_LSK_LBS: '',
      Received_Net_Less_LSK_LBS: '',
      Received_DollarValue: '',
      Received_TotalFreight_LBS: '',
      ADD_USER: '',
      Entered_Into_Computer_By: '',
      Inventory_Ind: '',
      Other_Weight: '',
      From_Organic_Ind: '',
      To_Organic_Ind: '',
      UR_WtOfFMSample_Gms: '',
      UR_CleanSampleWt_500Gms: '',
      UR_CleanSampleWt_1000Gms: '',
      UR_SMKRS_gms: '',
      UR_SMKRS_per: '',
      UR_SMK_RidingScreen_1_Per: '',
      UR_SMK_RidingScreen_2_Per: '',
      UR_SMK_RidingScreen_3_Per: '',
      UR_SMK_RidingScreen_4_Per: '',
      InvenUR_SMK_RidingScreen_4_Pertory_Ind: '',
      UR_SoundSplits_Gms: '',
      UR_SoundSplits_Per: '',
      UR_TotalSMK_Per: '',
      UR_OK_Gms: '',
      UR_OK_Per: '',
      UR_OK_RidingScreen_Per: '',
      UR_OK_FallThroughScreen_Per: '',
      UR_OK_DamageRiding_Per: '',
      UR_DamageSplits_Gms: '',
      UR_Damage_Gms: '',
      UR_Damage_Per: '',
      UR_Total_Kernals_Per: '',
      UR_Total_Hulls_Gms: '',
      UR_Total_Hulls_Per: '',
      UR_Total_Kernals_Hulls: '',
      UR_FreezeDamage_Gms: '',
      UR_FreezeDamage_Per: '',
      UR_PittingDamage_Gms: '',
      UR_PittingDamage_Per: '',
      UR_ConcealedRMD_Gms: '',
      UR_ConcealedRMD_Per: '',
      UR_Valencia: '',
      UR_Valencia_Cracked_Broken_Gms: '',
      UR_Valencia_Cracked_Broken_Per: '',
      UR_Virginia: '',
      UR_Virginia_DiscoloredShells_Gms: '',
      UR_Virginia_DiscoloredShells_Per: '',
      UR_Virginia_HullBrightness_Gms: '',
      UR_Virginia_HullBrightness_Per: '',
      UR_Virginia_Jumbo_Gms: '',
      UR_Virginia_Jumbo_Per: '',
      UR_ELK_Gms: '',
      UR_ELK_Per: '',
      UR_ForeignMaterial_Gms: '',
      UR_ForeignMaterial_Per: '',
      UR_Total_LSK_Gms: '',
      UR_Total_LSK_Per: '',
      UR_LSK_RidingScreen_Per: '',
      UR_LSK_FallThroughScreen_Per: '',
      UR_LSK_DamageRidingScreen_Per: '',
      UR_MoistureReading: '',
      UR_Fancy_Gms: '',
      UR_Fancy_Per: '',
      UR_FlavusFound_Yes: '',
      UR_FlavusFound_No: '',
      UR_VicamReading: '',
      Remarks: '',
      mtransferStatus: ''
    };

    let lstrxml = [];
    let LstrXML_Subs = [];

    if (sTransferStatus === 'Pending') {
      objData.mtransferStatus = '';
    } else if (sTransferStatus === 'Shipped') {
      objData.mtransferStatus = 'Control Copy';
    } else if (sTransferStatus === 'Received') {
      objData.mtransferStatus = 'File Copy';
    }

    lstrxml = await StockTransferService.RetrieveTransferHeaderDetails(sTransferNum, sTransferType, sShpBuyPt);
    objData.ShippedDate = ConvertDateToMMDDYYYY(new Date(lstrxml[0].shp_date)) == '01/01/1' ? '' : ConvertDateToMMDDYYYY(new Date(lstrxml[0].shp_date));
    objData.Received_Date = ConvertDateToMMDDYYYY(new Date(lstrxml[0].rec_date)) == '01/01/1' ? '' : ConvertDateToMMDDYYYY(new Date(lstrxml[0].rec_date));
    objData.TicketNumber = lstrxml[0].transfer_num;
    objData.TicketType = lstrxml[0].transfer_type;

    if(objData.TicketType != undefined && objData.TicketType != null && objData.TicketType != '' && objData.TicketType == 'ST'){
      objData.TicketData = objData.TicketNumber
    }
    else{
      objData.TicketData = objData.TicketType + '&nbsp;' + objData.TicketNumber
    }

    LstrXML_Subs = await SettlementService.RetrieveBuyingPointControlDetails('PN9000', '', '', '', lstrxml[0].shp_buy_pt);
    objData.Location_Name_1 = LstrXML_Subs[0].buyPtName;
    objData.Location_Number_1 = LstrXML_Subs[0].buyPtId;
    objData.Location_Address_1 = LstrXML_Subs[0].physicalAddress;
    objData.Location_Phone_1 = LstrXML_Subs[0].buyPtPhone;

    LstrXML_Subs = await SettlementService.RetrieveBuyingPointControlDetails('PN9000', '', '', '', lstrxml[0].rec_buy_pt);
    objData.Location_Name_2 = LstrXML_Subs[0].buyPtName;
    objData.Location_Number_2 = LstrXML_Subs[0].buyPtId;
    objData.Location_Address_2 = LstrXML_Subs[0].physicalAddress;
    objData.Location_Phone_2 = LstrXML_Subs[0].buyPtPhone;

    objData.TruckLine = lstrxml[0].truck_line;
    objData.TrailerLicenseNumber = lstrxml[0].vehichle_num;
    objData.DispatchNumber = lstrxml[0].dispatch_num;
    objData.DriversSignature = lstrxml[0].driver_name;

    if (lstrxml[0].farm_id !== '' && lstrxml[0].farm_id !== null && lstrxml[0].farm_id !== undefined) {
      objData.FF_State = lstrxml[0].state_abbr;
      objData.FF_StateDisplay = objData.FF_State == undefined || objData.FF_State == null || objData.FF_State == '' ? '' : 'State: ' + objData.FF_State

      LstrXML_Subs = await ContractManagementService.RetrieveCountyControlDetails('NA', false, objData.FF_State, lstrxml[0].county_id);
      objData.FF_County = LstrXML_Subs[0].countyName.trim();
      objData.FF_CountyDisplay = objData.FF_County == undefined || objData.FF_County == null || objData.FF_County == '' ? '' : 'County: ' + objData.FF_County

      LstrXML_Subs = await ContractManagementService.RetrieveFarmControlDetails('', '', objData.Location_Number_1, objData.FF_State, lstrxml[0].county_id, lstrxml[0].farm_id, lstrxml[0].farm_suffix);
      objData.FF_Farm = LstrXML_Subs[0].farm_id + ' - ' + LstrXML_Subs[0].farm_suffix + ' - ' + LstrXML_Subs[0].farm_name;
      objData.FF_FarmDisplay = objData.FF_Farm == undefined || objData.FF_Farm == null || objData.FF_Farm == '' ? '' : 'Farm: ' + objData.FF_Farm
    }
    objData.Peanut_Type_ID = lstrxml[0].pnut_type_id;
    
    LstrXML_Subs = await ContractManagementService.RetrievePeanutTypeControls(lstrxml[0].pnut_type_id);
    objData.Peanut_Type_Name = LstrXML_Subs[0].pnutTypeName;
    
    objData.Shipped_Loaded_From_WarehouseNumber = lstrxml[0].shp_whouse;
    objData.Shipped_Loaded_From_BinNumber = lstrxml[0].shp_bin;
    objData.Shipped_ScaleTicketNumber = lstrxml[0].shp_scale_tick;
    objData.Shipped_GrossWt_LBS = lstrxml[0].shp_tare_wt;
    objData.Shipped_TareWt_LBS = lstrxml[0].shp_vehicle_wt;
    objData.Shipped_NetWt_LBS = lstrxml[0].shp_peanut_wt;
    objData.Shipped_Peanut_Variety_ID = lstrxml[0].from_pnut_variety_id;

    if (objData.Shipped_Peanut_Variety_ID !== null &&
      objData.Shipped_Peanut_Variety_ID !== '' &&
      objData.Shipped_Peanut_Variety_ID !== undefined) {
        LstrXML_Subs = await ContractManagementService.RetrievePeanutVarietyControls(objData.Location_Number_1, '', '', objData.Peanut_Type_ID, objData.Shipped_Peanut_Variety_ID);
        objData.Shipped_Peanut_Variety_Name = LstrXML_Subs[0]?.pnut_variety_name;

        if (LstrXML_Subs[0]?.symbol_ind === 'TRADEMARK') {
          objData.Shipped_Peanut_Variety_Name = objData.Shipped_Peanut_Variety_Name || '';
        } else if (LstrXML_Subs[0]?.symbol_ind === 'REGISTERED TRADEMARK') {
          objData.Shipped_Peanut_Variety_Name = objData.Shipped_Peanut_Variety_Name || '';
        } else if (LstrXML_Subs[0]?.symbol_ind === 'COPYRIGHT') {
          objData.Shipped_Peanut_Variety_Name = objData.Shipped_Peanut_Variety_Name || '';
        } else {
          objData.Shipped_Peanut_Variety_Name = objData.Shipped_Peanut_Variety_Name || '';
        }
    } else {
      objData.Shipped_Peanut_Variety_Name = '';
    }

    if (lstrxml[0].from_seed_gen === 'C') {
      objData.Shipped_Peanut_Gen = 'Certified';
    } else if (lstrxml[0].from_seed_gen === 'F') {
      objData.Shipped_Peanut_Gen = 'Foundation';
    } else if (lstrxml[0].from_seed_gen === 'R') {
      objData.Shipped_Peanut_Gen = 'Registered';
    } else {
      objData.Shipped_Peanut_Gen = '';
    }

    objData.Shipped_Peanut_Seg = 'Seg ' + lstrxml[0].seg_type;

    if (lstrxml[0].from_oleic_ind === 'H') {
      objData.Shipped_Oleic_Ind = "High";
    } else if (lstrxml[0].from_oleic_ind === 'M') {
      objData.Shipped_Oleic_Ind = "Mid";
    } else {
      objData.Shipped_Oleic_Ind = "";
    }

    if (lstrxml[0].from_edible_oil_ind === 'E') {
      objData.Shipped_Edible_Oil_Ind = "Edible"
    } else if (lstrxml[0].from_edible_oil_ind === 'O') {
      objData.Shipped_Edible_Oil_Ind = "Oil";
    } else {
      objData.Shipped_Edible_Oil_Ind = "";
    }

    objData.Shipped_Split_Scale = lstrxml[0].shp_split_scale;
    if(lstrxml[0]?.shp_split_scale == 'Y'){
      objData.ShippedHeader = "Shipped:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Split Weight)"
    }
    else{
      objData.ShippedHeader = "Shipped:"
    }
    objData.Received_Loaded_To_WarehouseNumber = lstrxml[0].rec_whouse;
    objData.Received_Loaded_To_BinNumber = lstrxml[0].rec_bin;
    objData.Received_ScaleTicketNumber = lstrxml[0].rec_scale_tick;
    objData.Received_GrossWt_LBS = lstrxml[0].rec_tare_wt;
    objData.Received_TareWt_LBS = lstrxml[0].rec_vehicle_wt;
    objData.Received_NetWt_LBS = lstrxml[0].rec_peanut_wt;
    objData.Received_Peanut_Variety_ID = lstrxml[0].to_pnut_variety_id;

    if (objData.Received_Peanut_Variety_ID !== null &&
      objData.Received_Peanut_Variety_ID !== '' &&
      objData.Received_Peanut_Variety_ID !== undefined) {
        LstrXML_Subs = await ContractManagementService.RetrievePeanutVarietyControls(objData.Location_Number_1, '', '', objData.Peanut_Type_ID, objData.Received_Peanut_Variety_ID);
        objData.Received_Peanut_Variety_Name = LstrXML_Subs[0]?.pnut_variety_name;

        if (LstrXML_Subs[0]?.symbol_ind === 'TRADEMARK') {
          objData.Received_Peanut_Variety_Name = objData.Received_Peanut_Variety_Name || '';
        } else if (LstrXML_Subs[0]?.symbol_ind === 'REGISTERED TRADEMARK') {
          objData.Received_Peanut_Variety_Name = objData.Received_Peanut_Variety_Name || '';
        } else if (LstrXML_Subs[0]?.symbol_ind === 'COPYRIGHT') {
          objData.Received_Peanut_Variety_Name = objData.Received_Peanut_Variety_Name || '';
        } else {
          objData.Received_Peanut_Variety_Name = objData.Received_Peanut_Variety_Name || '';
        }
    } else {
      objData.Received_Peanut_Variety_Name = '';
    }

    if (lstrxml[0].to_seed_gen === 'C') {
      objData.Received_Peanut_Gen = "Certified";
    } else if (lstrxml[0].to_seed_gen === 'F') {
      objData.Received_Peanut_Gen = "Foundation";
    } else if (lstrxml[0].to_seed_gen === 'R') {
      objData.Received_Peanut_Gen = "Registered";
    } else {
      objData.Received_Peanut_Gen = "";
    }

    objData.Received_Peanut_Seg = "Seg " + lstrxml[0].seg_type;

    if (lstrxml[0].to_oleic_ind === 'H') {
      objData.Received_Oleic_Ind = "High";
    } else if (lstrxml[0].to_oleic_ind === 'M') {
      objData.Received_Oleic_Ind = "Mid";
    } else {
      objData.Received_Oleic_Ind = "";
    }

    if (lstrxml[0].to_edible_oil_ind === 'E') {
      objData.Received_Edible_Oil_Ind = "Edible";
    } else if (lstrxml[0].to_edible_oil_ind === 'O') {
      objData.Received_Edible_Oil_Ind = "Oil";
    } else {
      objData.Received_Edible_Oil_Ind = "";
    }

    objData.Received_Split_Scale = lstrxml[0].rec_split_scale;
    if(lstrxml[0]?.rec_split_scale == 'Y'){
      objData.ReceivedHeader = 'Received:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Split Weight)'
    }
    else{
      objData.ReceivedHeader = 'Received:'
    }

    if (lstrxml[0].shp_peanut_wt !== "" && lstrxml[0].shp_peanut_wt !== null && lstrxml[0].shp_peanut_wt !== undefined &&
      lstrxml[0].rec_peanut_wt !== "" && lstrxml[0].rec_peanut_wt !== null && lstrxml[0].rec_peanut_wt !== undefined) {
      objData.Received_WtVariance = Number(lstrxml[0].rec_peanut_wt) - Number(lstrxml[0].shp_peanut_wt);
    } else {
      objData.Received_WtVariance = "";
    }

    objData.Received_FM_LBS = Number(lstrxml[0].fm_wt);
    objData.Received_WeightLessFM_LBS = Number(lstrxml[0].gross_wt) - Number(lstrxml[0].fm_wt);
    objData.Received_ExcessMoisture_LBS = Number(lstrxml[0].ex_moist_wt);
    objData.Received_Freight_Rate_Per_Ton = Number(lstrxml[0].freight_rate)
    objData.Received_FM_NetWt_LBS = Number(lstrxml[0].net_wt);
    objData.Received_LSK_LBS = Number(lstrxml[0].lsk_wt);
    objData.Received_Net_Less_LSK_LBS = Number(lstrxml[0].net_wt) - Number(lstrxml[0].lsk_wt);
    objData.Received_DollarValue = Number(lstrxml[0].basis_grade_amt);
    objData.Received_TotalFreight_LBS = Number(lstrxml[0].freight_amt);
    objData.ADD_USER = lstrxml[0].add_user;

    LstrXML_Subs = await ContractManagementService.RetrieveUserControlDetails(objData.ADD_USER);
    if (LstrXML_Subs[0]?.userName !== "" &&
      LstrXML_Subs[0]?.userName !== null &&
      LstrXML_Subs[0]?.userName !== undefined) {
      objData.Entered_Into_Computer_By = LstrXML_Subs[0]?.userName || '';
    } else {
      objData.Entered_Into_Computer_By = objData.ADD_USER;
    }

    if (lstrxml[0].inventory_ind === 'S') {
      objData.Inventory_Ind = 'Shipped Weight';
    } else if (lstrxml[0].inventory_ind === 'R') {
      objData.Inventory_Ind = 'Received Weight';
    } else if (lstrxml[0].inventory_ind === 'O') {
      objData.Inventory_Ind = 'Other Weight';
    }
    objData.Other_Weight = Number(lstrxml[0].other_wt);
    objData.From_Organic_Ind = lstrxml[0].from_organic_ind.toUpperCase();
    objData.To_Organic_Ind = lstrxml[0].to_organic_ind.toUpperCase();
    objData.UR_WtOfFMSample_Gms = Number(lstrxml[0].fm_sample);
    objData.UR_CleanSampleWt_500Gms = Number(lstrxml[0].cln_sample);
    objData.UR_CleanSampleWt_1000Gms = Number(lstrxml[0].cln_sample);
    objData.UR_SMKRS_gms = Number(lstrxml[0].smk_gr);
    objData.UR_SMKRS_per = Number(lstrxml[0].smk_pct);
    objData.UR_SMK_RidingScreen_1_Per = Number(lstrxml[0].smk_1_pct);
    objData.UR_SMK_RidingScreen_2_Per = Number(lstrxml[0].smk_2_pct);
    objData.UR_SMK_RidingScreen_3_Per = Number(lstrxml[0].smk_3_pct);
    objData.UR_SMK_RidingScreen_4_Per = Number(lstrxml[0].smk_4_pct);
    objData.UR_SoundSplits_Gms = Number(lstrxml[0].ss_gr);
    objData.UR_SoundSplits_Per = Number(lstrxml[0].ss_pct);
    objData.UR_TotalSMK_Per = Number(objData.UR_SMKRS_per) + Number(objData.UR_SoundSplits_Per)
    objData.UR_OK_Gms = Number(lstrxml[0].ok_gr);
    objData.UR_OK_Per = Number(lstrxml[0].ok_pct);
    objData.UR_OK_RidingScreen_Per = Number(lstrxml[0].ok_ride_pct)
    objData.UR_OK_FallThroughScreen_Per = Number(lstrxml[0].ok_fall_pct);
    objData.UR_OK_DamageRiding_Per = Number(lstrxml[0].ok_dam_pct)
    objData.UR_DamageSplits_Gms = Number(lstrxml[0].dam_split_gr);
    objData.UR_Damage_Gms = Number(lstrxml[0].dam_gr);
    objData.UR_Damage_Per = Number(lstrxml[0].dam_pct);

    if (isNaN(lstrxml[0].smk_pct) == false) {
        objData.UR_Total_Kernals_Per = Number(objData.UR_SMKRS_per) + Number(objData.UR_SoundSplits_Per) + Number(objData.UR_OK_Per) + Number(objData.UR_Damage_Per);
    } else {
        objData.UR_Total_Kernals_Per = "";
    }

    objData.UR_Total_Hulls_Gms = Number(lstrxml[0].hull_gr);
    objData.UR_Total_Hulls_Per = Number(lstrxml[0].hull_pct);
    if (isNaN(lstrxml[0].smk_pct) == false) {
      objData.UR_Total_Kernals_Hulls = Number(objData.UR_SMKRS_per) + Number(objData.UR_SoundSplits_Per) + Number(objData.UR_OK_Per) + Number(objData.UR_Total_Hulls_Per) + Number(objData.UR_Damage_Per);
    } else {
      objData.UR_Total_Kernals_Hulls = "";
    }

    objData.UR_FreezeDamage_Gms = Number(lstrxml[0].frez_dam_gr);
    objData.UR_FreezeDamage_Per = Number(lstrxml[0].frez_dam_pct);
    objData.UR_PittingDamage_Gms = Number(lstrxml[0].pit_dam_gr);
    objData.UR_PittingDamage_Per = Number(lstrxml[0].pit_dam_pct);
    objData.UR_ConcealedRMD_Gms = Number(lstrxml[0].cncl_rmd_gr);
    if (lstrxml[0].cncl_rmd_pct === undefined || lstrxml[0].cncl_rmd_pct === null || lstrxml[0].cncl_rmd_pct === '') {
      objData.UR_ConcealedRMD_Per = 0;
    } else {
      objData.UR_ConcealedRMD_Per = Number(lstrxml[0].cncl_rmd_pct);
    }
    if (lstrxml[0].pnut_type_id === "VI") {
        objData.UR_Valencia = "X";
    } else {
        objData.UR_Valencia = "";
    }
    objData.UR_Valencia_Cracked_Broken_Gms = Number(lstrxml[0].cr_br_gr);
    objData.UR_Valencia_Cracked_Broken_Per = Number(lstrxml[0].cr_br_pct);
    if (lstrxml[0].pnut_type_id === "VA") {
        objData.UR_Virginia = "X";
    } else {
        objData.UR_Virginia = "";
    }
    objData.UR_Virginia_DiscoloredShells_Gms = Number(lstrxml[0].dcol_shel_gr);
    objData.UR_Virginia_DiscoloredShells_Per = Number(lstrxml[0].dcol_shel_pct);
    objData.UR_Virginia_HullBrightness_Gms = Number(lstrxml[0].hul_brit_gr);
    objData.UR_Virginia_HullBrightness_Per = Number(lstrxml[0].hul_brit_pct);
    objData.UR_Virginia_Jumbo_Gms = Number(lstrxml[0].jumbo_gr);
    objData.UR_Virginia_Jumbo_Per = Number(lstrxml[0].jumbo_pct);
    objData.UR_ELK_Gms = Number(lstrxml[0].elk_gr);
    objData.UR_ELK_Per = Number(lstrxml[0].elk_pct);
    if (objData.UR_ForeignMaterial_Gms === undefined || objData.UR_ForeignMaterial_Gms === null || objData.UR_ForeignMaterial_Gms === '') {
      objData.UR_ForeignMaterial_Gms = 0;
    } else {
      objData.UR_ForeignMaterial_Gms = Number(lstrxml[0].m_gr);
    }
    objData.UR_ForeignMaterial_Per = Number(lstrxml[0].fm_pct);
    objData.UR_Total_LSK_Gms = Number(lstrxml[0].lsk_gr);
    objData.UR_Total_LSK_Per = Number(lstrxml[0].lsk_pct);
    objData.UR_LSK_RidingScreen_Per = Number(lstrxml[0].lsk_ride_pct);
    objData.UR_LSK_FallThroughScreen_Per = Number(lstrxml[0].lsk_fall_pct);
    objData.UR_LSK_DamageRidingScreen_Per = Number(lstrxml[0].lsk_dam_pct);
    objData.UR_MoistureReading = Number(lstrxml[0].moist_pct);
    objData.UR_Fancy_Gms = Number(lstrxml[0].fan_gr);
    objData.UR_Fancy_Per = Number(lstrxml[0].fan_pct);
    if (lstrxml[0].flavus_ind === "Y") {
        objData.UR_FlavusFound_Yes = "X"
        objData.UR_FlavusFound_No = ""
    } else {
        objData.UR_FlavusFound_Yes = ""
        objData.UR_FlavusFound_No = "X"
    }
    objData.UR_VicamReading = Number(lstrxml[0].vicam_ppb);
    objData.Remarks = lstrxml[0].remark;
    setData(thisObj, 'rptData', objData);
  }

  async function ReadFile() {
    let renderCode = await GetHtmlCode(90, 100, '')
    state.renderCode = renderCode
    state.renderUrl = getBlobURL(renderCode, 'text/html')
    document.getElementById("docFrame").setAttribute("src", state.renderUrl);
  }

  async function GetHtmlCode(fontSize, tableWidth, pageGap) {
    let obj = getData(thisObj, 'rptData');
    if(obj != undefined && obj != null && obj != ''){
      let renderCode = printRptData(fontSize, tableWidth, pageGap, obj);
      return renderCode;
    }
    else{
      return ''
    }
  }

  async function printRptData(fontSize, tableWidth, pageGap, obj) {
    //commented and added - added 20Nov -PRB0055004/INC6274591
   // let mStockTransfer = getData(thisObj, 'mTransferData');
      let Settled_1007_Num = getData(thisObj,'mStockTransfer');
      //end of changes
    Settled_1007_Num = Settled_1007_Num == undefined || Settled_1007_Num == null ? '' : Settled_1007_Num
    let optionPage = ''
    optionPage +=  `<html>
    <style>
        body:before{
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;  
            color: #0d745e;
            font-size: 250px;
            font-weight: 500px;
            display: grid;
            justify-content: center;
            align-content: center;
            opacity: 0.35;
            
        }
        @media print {
            @page :footer { display: none }    
            @page :header { display: none }    
            @page { margin-top: 0; margin-bottom: 0; }    
            html,
            body { height: 100%; width: 100%; margin: 0 !important; padding: 0 !important; }
        }
        .footer-table {
          height: 375px;
          text-align: center;
          border-collapse: collapse; 
        }
        .footer-table td, .footer-table th {
          border: 1px solid black; 
        }
        </style>
        ${pageGap}
        <p style="page-break-after: always">
        
        <table style="align-items: center; width: ${tableWidth}%; font-size:${fontSize}%; font-family: arial;">
          <tr>
              <td style="width: 25%; padding-left:0.5%; padding-right:1%; border:1px solid black;">
                Crop Year: ${obj.CropYear} <br />
                Shipped Date: ${obj.ShippedDate} <br />
                Received Date: ${obj.Received_Date}
              </td>
              <td style="width: 45%; padding-left:0.1%; text-align: center; padding-right: 0.1%;">
                <span style="font-size:150%; color: black;">
                  <b> Golden Peanut Co. <br /> 
                  Farmer Stock Delivery Ticket </b> <br />
                </span>
                <span><b>Bill Of Lading</b></span>
              </td>
              <td style="width: 30%; padding-left:4%;">
                <span style="border:1px solid black;">
                  Ticket Number: ${obj.TicketData} 
                </span> <br />
                <p>
                ${obj.FF_StateDisplay} &nbsp; ${obj.FF_CountyDisplay}<br />
                ${obj.FF_FarmDisplay}
                </p>
              </td>
          <tr>
        </table>
        <table style="padding-top: 1%; align-items: center; width: ${tableWidth}%; font-size:${fontSize}%; font-family: arial; vertical-align: bottom;">
          <tr>
              <td style="width: 15%;">
                Shipping Location:
              </td>
              <td style="width: 33%; border: 0; border-bottom: 1px solid #000;">
                ${obj.Location_Number_1} - ${obj.Location_Name_1} &nbsp;
              </td>
              <td style="width: 5%;">
                Address:
              </td>
              <td style="width: 23%; border: 0; border-bottom: 1px solid #000;">
                 ${obj.Location_Address_1} &nbsp;
              </td>
              <td style="width: 8%;">
                Phone #
              </td>
              <td style="width: 17%; border: 0; border-bottom: 1px solid #000;">
                ${obj.Location_Phone_1}
              </td>
          <tr>
        </table>
        <table style="align-items: center; width: ${tableWidth}%; font-size:${fontSize}%; font-family: arial; vertical-align: bottom;">
          <tr>
            <td style="width: 15%;">
              Receiving Location:
            </td>
            <td style="width: 33%; border: 0; border-bottom: 1px solid #000;">
              ${obj.Location_Number_2} - ${obj.Location_Name_2} &nbsp;
            </td>
            <td style="width: 5%;">
              Address:
            </td>
            <td style="width: 23%; border: 0; border-bottom: 1px solid #000;">
              ${obj.Location_Address_2} &nbsp;
            </td>
            <td style="width: 8%;">
              Phone #
            </td>
            <td style="width: 17%; border: 0; border-bottom: 1px solid #000;">
              ${obj.Location_Phone_2}
            </td>
          <tr>
        </table>
        <table style="align-items: center; width: ${tableWidth}%; font-size:${fontSize}%; font-family: arial; vertical-align: bottom;">
          <tr>
            <td style="width: 10%;">
              Truck Line: 
            </td>
            <td style="width: 17%; border: 0; border-bottom: 1px solid #000;">
              ${obj.TruckLine}
            </td>
            <td style="width: 5%;">
              Carrier:  
            </td>
            <td style="width: 15%; border: 0; border-bottom: 1px solid #000;">
              ${obj.mTransferStatus_SCAC}
            </td>
            <td style="width: 15%;">
              Trailer License No. 
            </td>
            <td style="width: 14%; border: 0; border-bottom: 1px solid #000;">
              ${obj.TrailerLicenseNumber}
            </td>
            <td style="width: 9%;">
              Dispatch #
            </td>
            <td style="width: 15%; border: 0; border-bottom: 1px solid #000;">
               ${obj.DispatchNumber}
            </td>
          <tr>
        </table>
        <table style="padding-top: 1%; align-items: center; width: ${tableWidth}%; font-size:${fontSize}%; font-family: arial; vertical-align: bottom;">
          <tr>
            <td style="width: 6%;">
              Driver Name:
            </td>
            <td style="width: 10%; border: 0; border-bottom: 1px solid #000;">
              ${obj.DriversSignature}
            </td>
            <td style="width: 8%;">
              Driver's Signature:
            </td>
            <td style="width: 21%; border: 0; border-bottom: 1px solid #000;">
              
            </td>
            <td style="width: 2%;">
              1007 #
            </td>
            <td style="width: 8%; border: 0; border-bottom: 1px solid #000;">
              ${Settled_1007_Num}
            </td>
          <tr>
        </table>
        <table style="align-items: center; width: ${tableWidth}%; font-size:${fontSize}%; font-family: arial;">
          <tr>
            <td style="width: 50%; padding-left:0.5%; padding-right:1%; border:1px solid black;">
              <table style="font-family: arial; font-size:100%;">
                <tr>
                  <td colspan="4">
                    <span style="border: 0; border-bottom: 1px solid #000;">
                      ${obj.ShippedHeader}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style="width: 3%;">
                    Whse#
                  </td>
                  <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                     ${obj.Shipped_Loaded_From_WarehouseNumber}
                  </td>
                  <td style="width: 2%;">
                    Peanut Type:
                  </td>
                  <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                    ${obj.Peanut_Type_Name}
                  </td>
                </tr>
                <tr>
                  <td style="width: 3%;">
                    Bin#
                  </td>
                  <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                    ${obj.Shipped_Loaded_From_BinNumber}
                  </td>
                  <td style="width: 2%;">
                    Variety:
                  </td>
                  <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                    ${obj.Shipped_Peanut_Variety_Name}
                  </td>
                </tr>
                <tr>
                  <td style="width: 4%;">
                    Scale Ticket#
                  </td>
                  <td style="width: 5%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                    ${obj.Shipped_ScaleTicketNumber}
                  </td>
                  <td style="width: 2%;">
                    Generation:
                  </td>
                  <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                    ${obj.Shipped_Peanut_Gen}
                  </td>
                </tr>
                <tr>
                  <td style="width: 3%;">
                    Gross Wt.
                  </td>
                  <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                    ${obj.Shipped_GrossWt_LBS} &nbsp; lbs.
                  </td>
                  <td style="width: 2%;">
                    Seg Type:
                  </td>
                  <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                    ${obj.Shipped_Peanut_Seg}
                  </td>
                </tr>
                <tr>
                  <td style="width: 3%;">
                    Tare Wt.
                  </td>
                  <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                    ${obj.Shipped_TareWt_LBS} &nbsp; lbs.
                  </td>
                  <td style="width: 2%;">
                    Oleic:
                  </td>
                  <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                    ${obj.Shipped_Oleic_Ind}
                  </td>
                </tr>
                <tr>
                  <td style="width: 3%;">
                    Net Wt.
                  </td>
                  <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                    ${obj.Shipped_NetWt_LBS} &nbsp; lbs.
                  </td>
                  <td style="width: 2%;">
                    Edible/Oil:
                  </td>
                  <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                    ${obj.Shipped_Edible_Oil_Ind}
                  </td>
                </tr>
                <tr>
                  <td style="width: 3%;">
                  </td>
                  <td style="width: 6%;">
                  </td>
                  <td style="width: 2%;">
                    Organic:
                  </td>
                  <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                    ${obj.From_Organic_Ind}
                  </td>
                </tr>
              </table>
            </td>
            <td style="width: 50%; padding-left:0.5%; padding-right:1%; border:1px solid black;">
              <table style="font-family: arial; font-size:100%;">
                <tr>
                  <td colspan="4">
                    <span style="border: 0; border-bottom: 1px solid #000;">
                      ${obj.ReceivedHeader}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style="width: 3%;">
                    Whse#
                  </td>
                  <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                    ${obj.Received_Loaded_To_WarehouseNumber}
                  </td>
                  <td style="width: 2%;">
                    Peanut Type:
                  </td>
                  <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                    ${obj.Peanut_Type_Name}
                  </td>
                </tr>
                <tr>
                  <td style="width: 3%;">
                    Bin#
                  </td>
                  <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                    ${obj.Received_Loaded_To_BinNumber}
                  </td>
                  <td style="width: 2%;">
                    Variety:
                  </td>
                  <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                    ${obj.Received_Peanut_Variety_Name}
                  </td>
                </tr>
                <tr>
                  <td style="width: 4%;">
                    Scale Ticket#
                  </td>
                  <td style="width: 5%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                    ${obj.Received_ScaleTicketNumber}
                  </td>
                  <td style="width: 2%;">
                    Generation:
                  </td>
                  <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                    ${obj.Received_Peanut_Gen}
                  </td>
                </tr>
                <tr>
                  <td style="width: 3%;">
                    Gross Wt.
                  </td>
                  <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                    ${obj.Received_GrossWt_LBS} &nbsp; lbs.
                  </td>
                  <td style="width: 2%;">
                    Seg Type:
                  </td>
                  <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                    ${obj.Received_Peanut_Seg}
                  </td>
                </tr>
                <tr>
                  <td style="width: 3%;">
                    Tare Wt.
                  </td>
                  <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                    ${obj.Received_TareWt_LBS} &nbsp; lbs.
                  </td>
                  <td style="width: 2%;">
                    Oleic:
                  </td>
                  <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                    ${obj.Received_Oleic_Ind}
                  </td>
                </tr>
                <tr>
                  <td style="width: 3%;">
                    Net Wt.
                  </td>
                  <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                    ${obj.Received_NetWt_LBS} &nbsp; lbs.
                  </td>
                  <td style="width: 2%;">
                    Edible/Oil:
                  </td>
                  <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                    ${obj.Received_Edible_Oil_Ind}
                  </td>
                </tr>
                <tr>
                  <td style="width: 3%;">
                  </td>
                  <td style="width: 6%;">
                  </td>
                  <td style="width: 2%;">
                    Organic:
                  </td>
                  <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                    ${obj.To_Organic_Ind}
                  </td>
                </tr>
              </table>
            </td>
          <tr>
        </table>
        <table style="align-items: center; width: ${tableWidth}%; font-size:${fontSize}%; font-family: arial;">
          <tr style="height: 35px;">
            <td style="width: 70%; padding-left:0.5%; padding-right:1%; border:1px solid black;">
              Update Inventory With: &nbsp;&nbsp;&nbsp;${obj.Inventory_Ind} &nbsp;&nbsp; <u> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              ${obj.Other_Weight} lbs. </u>
            </td>
            <td style="width: 30%; padding-left:0.5%; padding-right:1%; border:1px solid black;">
              <span style="display: inline-block; width: 49%;">
                Weight Variance:
              </span>
              <span style="display: inline-block; width: 49%; text-align: right;">
                <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${obj.Received_WtVariance}&nbsp;lbs.</u>
              </span>
            </td>
          <tr>
        </table>
        <table style="align-items: center; margin-left: 2px; width: 99.6%; font-size:${fontSize}%; font-family: arial; border:1px solid black;">
          <tr>
            <td style="width: 3%;">
              FM Weight:
            </td>
            <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
              ${obj.Received_FM_LBS}&nbsp;lbs.
            </td>
            <td style="width: 3%; padding-left: 1%;">
              Dollar Value:
            </td>
            <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
              ${obj.Received_DollarValue}
            </td>
            <td style="width: 3%; padding-left: 1%;">
              Net Weight:
            </td>
            <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
              ${obj.Received_FM_NetWt_LBS}&nbsp;lbs.
            </td>
          </tr>
          <tr>
            <td style="width: 3%;">
              Weight Less FM:
            </td>
            <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
              ${obj.Received_WeightLessFM_LBS}&nbsp;lbs.
            </td>
            <td style="width: 5%; padding-left: 1%;">
              Freight - Rate/Ton:
            </td>
            <td style="width: 4%; border: 0; border-bottom: 1px solid #000; text-align: right;">
              ${obj.Received_Freight_Rate_Per_Ton}
            </td>
            <td style="width: 3%; padding-left: 1%;">
              LSK Weight:
            </td>
            <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
              ${obj.Received_LSK_LBS}&nbsp;lbs.
            </td>
          </tr>
          <tr>
            <td style="width: 3%;">
              Excess Moisture:
            </td>
            <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
              ${obj.Received_ExcessMoisture_LBS}&nbsp;lbs.
            </td>
            <td style="width: 3%; padding-left: 1%;">
              Total Freight:
            </td>
            <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
              $&nbsp;${obj.Received_TotalFreight_LBS}
            </td>
            <td style="width: 4%; padding-left: 1%;">
              Net-Less LSK:
            </td>
            <td style="width: 5%; border: 0; border-bottom: 1px solid #000; text-align: right;">
              ${obj.Received_Net_Less_LSK_LBS}&nbsp;lbs.
            </td>
          </tr>
          <tr>
            <td colspan="6">
              &nbsp;
            </td>
          </tr>
          <tr>
            <td style="width: 5%;">
              Receiving Signature:
            </td>
            <td colspan="2" style="width: 2%; border: 0; border-bottom: 1px solid #000;">
            </td>
            <td style="width: 8%; padding-left: 1%;">
              Entered into Computer By:
            </td>
            <td colspan="2" style="width: 2%; border: 0; border-bottom: 1px solid #000; text-align: right;">
              ${obj.Entered_Into_Computer_By}
            </td>
          </tr>
        </table>
        <table style="align-items: center; width: ${tableWidth}%; font-size:62%; font-family: arial;">
          <tr>
            <td style="width: 30%; vertical-align: top;">
              <table style="margin-left: -1px; font-family: arial; font-size:100%;" class="footer-table">
                <tr>
                  <td colspan="4" style="width: 3%;">
                    Unofficial Re-Grade
                  </td>
                </tr>
                <tr>
                  <td rowspan="3" colspan="2" style="width: 3%;">
                    Weight of FM Samp <u>&nbsp;&nbsp;&nbsp;${obj.UR_WtOfFMSample_Gms.toFixed(1)}</u>&nbsp;g.
                  </td>
                  <td colspan="2" style="width: 2%;">
                    Clean Sample Wt.
                  </td>
                </tr>
                <tr>
                  <td style="width: 2%;">
                    
                  </td>
                  <td style="width: 2%; text-align: right;">
                    500 g.
                  </td>
                </tr>
                <tr>
                  <td style="width: 2%;">
                    
                  </td>
                  <td style="width: 2%; text-align: right;">
                    1000 g.
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    SMKRS
                  </td>
                  <td style="width: 2%; text-align: right;">
                    ${obj.UR_SMKRS_gms.toFixed(1)}&nbsp;g.
                  </td>
                  <td style="width: 2%; text-align: right;">
                    ${obj.UR_SMKRS_per.toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    SMK 1 Riding
                  </td>
                  <td style="width: 2%; background-color: gray;">
                    
                  </td>
                  <td style="width: 2%; text-align: right;">
                    ${obj.UR_SMK_RidingScreen_1_Per.toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    SMK 2 Riding
                  </td>
                  <td style="width: 2%; background-color: gray;">
                    
                  </td>
                  <td style="width: 2%; text-align: right;">
                    ${obj.UR_SMK_RidingScreen_2_Per.toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    SMK 3 Riding
                  </td>
                  <td style="width: 2%; background-color: gray;">
                    
                  </td>
                  <td style="width: 2%; text-align: right;">
                    ${obj.UR_SMK_RidingScreen_3_Per.toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    SMK 4 Riding
                  </td>
                  <td style="width: 2%; background-color: gray;">
                    
                  </td>
                  <td style="width: 2%; text-align: right;">
                    ${obj.UR_SMK_RidingScreen_4_Per.toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    Sound Splits
                  </td>
                  <td style="width: 2%; text-align: right;">
                    ${obj.UR_SoundSplits_Gms.toFixed(1)}&nbsp;g.
                  </td>
                  <td style="width: 2%; text-align: right;">
                    ${obj.UR_SoundSplits_Per.toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    Total SMK
                  </td>
                  <td style="width: 2%; background-color: gray;">
                    
                  </td>
                  <td style="width: 2%; text-align: right;">
                    ${obj.UR_TotalSMK_Per.toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    OK
                  </td>
                  <td style="width: 2%; text-align: right;">
                    ${obj.UR_OK_Gms.toFixed(1)}&nbsp;g.
                  </td>
                  <td style="width: 2%; text-align: right;">
                    ${obj.UR_OK_Per.toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                  OK Ridding Screen
                  </td>
                  <td style="width: 2%; background-color: gray;">
                    
                  </td>
                  <td style="width: 2%; text-align: right;">
                    ${obj.UR_OK_RidingScreen_Per.toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                  OK Fall Through Screen
                  </td>
                  <td style="width: 2%; background-color: gray;">
                    
                  </td>
                  <td style="width: 2%; text-align: right;">
                    ${obj.UR_OK_FallThroughScreen_Per.toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    OK Damage Riding
                  </td>
                  <td style="width: 2%; background-color: gray;">
                    
                  </td>
                  <td style="width: 2%; text-align: right;">
                    ${obj.UR_OK_DamageRiding_Per.toFixed(1)}&nbsp;%
                  </td>
                </tr>
              </table>
            </td>
            <td style="width: 30%; vertical-align: top;">
              <table style="font-family: arial; font-size:100%;" class="footer-table">
                <tr>
                  <td colspan="2" style="width: 2%;">
                    Damage Splits
                  </td>
                  <td style="width: 2%; text-align: right;">
                    ${obj.UR_DamageSplits_Gms.toFixed(1)}&nbsp;g.
                  </td>
                  <td style="width: 2%; background-color: gray;">
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    Damage
                  </td>
                  <td style="width: 2%; text-align: right;">
                    ${obj.UR_Damage_Gms.toFixed(1)}&nbsp;g.
                  </td>
                  <td style="width: 2%; text-align: right;">
                    ${obj.UR_Damage_Per.toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    Total Kernals
                  </td>
                  <td style="width: 2%; background-color: gray;">
                    
                  </td>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_Total_Kernals_Per).toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    Hulls
                  </td>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_Total_Hulls_Gms).toFixed(1)}&nbsp;g.
                  </td>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_Total_Hulls_Per).toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    Total Kernals and Hulls
                  </td>
                  <td style="width: 2%; background-color: gray;">
                    
                  </td>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_Total_Kernals_Hulls).toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    Freeze Damage
                  </td>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_FreezeDamage_Gms).toFixed(1)}&nbsp;g.
                  </td>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_FreezeDamage_Per).toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    Pitting Damage
                  </td>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_PittingDamage_Gms).toFixed(1)}&nbsp;g.
                  </td>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_PittingDamage_Per).toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    Concealed RMD
                  </td>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_ConcealedRMD_Gms).toFixed(1)}&nbsp;g.
                  </td>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_ConcealedRMD_Per).toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td rowspan="4" colspan="2" style="width: 2%;">
                    Valencia <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></br>
                    or</br>
                    Virginia<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></br>Type
                  </td>
                  <td colspan="2" style="width: 2%;">
                    Cracked or Broken
                  </td>
                </tr>
                <tr>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_Valencia_Cracked_Broken_Gms).toFixed(1)}&nbsp;g.
                  </td>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_Valencia_Cracked_Broken_Per).toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    Discolored Shells
                  </td>
                </tr>
                <tr>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_Virginia_DiscoloredShells_Gms).toFixed(1)}&nbsp;g.
                  </td>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_Virginia_DiscoloredShells_Per).toFixed(1)}&nbsp;%
                  </td>
                </tr>
              </table>
            </td>
            <td style="width: 30%; vertical-align: top;">
              <table style="font-family: arial; font-size:100%;" class="footer-table">
                <tr>
                  <td rowspan="4" colspan="2" style="width: 2%;">
                    Virginia</br>
                    Type</br>
                    Only
                  </td>
                  <td colspan="2" style="width: 2%;">
                    Hull Brightness
                  </td>
                </tr>
                <tr>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_Virginia_HullBrightness_Gms).toFixed(1)}&nbsp;g.
                  </td>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_Virginia_HullBrightness_Per).toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    Jumbo
                  </td>
                </tr>
                <tr>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_Virginia_Jumbo_Gms).toFixed(1)}&nbsp;g.
                  </td>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_Virginia_Jumbo_Per).toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    ELK
                  </td>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_ELK_Gms).toFixed(1)}&nbsp;g.
                  </td>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_ELK_Per).toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    Foreign Material
                  </td>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_ForeignMaterial_Gms).toFixed(1)}&nbsp;g.
                  </td>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_ForeignMaterial_Per).toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    Total LSK
                  </td>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_Total_LSK_Gms).toFixed(1)}&nbsp;g.
                  </td>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_Total_LSK_Per).toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    +LSK Riding Screen
                  </td>
                  <td style="width: 2%; background-color: gray;">
                    
                  </td>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_LSK_RidingScreen_Per).toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    -LSK Fall Through
                  </td>
                  <td style="width: 2%; background-color: gray;">
                    
                  </td>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_LSK_FallThroughScreen_Per).toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    LSK Dam. Riding S
                  </td>
                  <td style="width: 2%; background-color: gray;">
                    
                  </td>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_LSK_DamageRidingScreen_Per).toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    Moisture Reading
                  </td>
                  <td colspan="2" style="width: 2%; text-align: right;">
                  ${Number(obj.UR_MoistureReading).toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    Fancy
                  </td>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_Fancy_Gms).toFixed(1)}&nbsp;g.
                  </td>
                  <td style="width: 2%; text-align: right;">
                  ${Number(obj.UR_Fancy_Per).toFixed(1)}&nbsp;%
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    A. Flavus Found</br>
                    (Seg. 3)
                  </td>
                  <td colspan="2" style="width: 2%;">
                  Yes <u>&nbsp;&nbsp;${obj.UR_FlavusFound_Yes}&nbsp;&nbsp;&nbsp;</u>&nbsp;No<u>&nbsp;&nbsp;${obj.UR_FlavusFound_No}&nbsp;&nbsp;&nbsp;</u>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" style="width: 2%;">
                    Vicam Riding
                  </td>
                  <td colspan="2" style="width: 2%; text-align: right;">
                    ${obj.UR_VicamReading}&nbsp;ppb
                  </td>
                </tr>
              </table>
            </td>
          <tr>
        </table>
        <table style="font-family: arial;width: 100%;border: none;">
          <tr style="width: 100%;border: none;">
            <td style="width: 100%;border: none;"><u><b>
              Remarks: </b></u>
            </td>
          </tr>
          <tr style="width: 100%;border: none;">
            <td style="width: 100%;border: none;">
              &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp${obj.Remarks}
            </td>
          </tr>
        </table>
        <table style="font-family: arial;width: 100%;height: 50px;border: none;">
          <tr style="width: 100%;border: none;text-align: center;">
            <td style="width: 100%;border: none;"><b>
              ${obj.mtransferStatus}</b>
            </td>
          </tr>
        </table>
        </p>`

    if(obj.mtransferStatus == 'Control Copy'){
      optionPage += `<html>
      <style>
          body:before{
              position: fixed;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              z-index: -1;  
              color: #0d745e;
              font-size: 250px;
              font-weight: 500px;
              display: grid;
              justify-content: center;
              align-content: center;
              opacity: 0.35;
              
          }
          @media print {
              @page :footer { display: none }    
              @page :header { display: none }    
              @page { margin-top: 0; margin-bottom: 0; }    
              html,
              body { height: 100%; width: 100%; margin: 0 !important; padding: 0 !important; }
          }
          .footer-table {
            height: 375px;
            text-align: center;
            border-collapse: collapse; 
          }
          .footer-table td, .footer-table th {
            border: 1px solid black; 
          }
          </style>
          ${pageGap}
          <p style="page-break-after: always">
          
          <table style="align-items: center; width: ${tableWidth}%; font-size:${fontSize}%; font-family: arial;">
            <tr>
                <td style="width: 25%; padding-left:0.5%; padding-right:1%; border:1px solid black;">
                  Crop Year: ${obj.CropYear} <br />
                  Shipped Date: ${obj.ShippedDate} <br />
                  Received Date: ${obj.Received_Date}
                </td>
                <td style="width: 45%; padding-left:0.1%; text-align: center; padding-right: 0.1%;">
                  <span style="font-size:150%; color: black;">
                    <b> Golden Peanut Co. <br /> 
                    Farmer Stock Delivery Ticket </b> <br />
                  </span>
                  <span><b>Bill Of Lading</b></span>
                </td>
                <td style="width: 30%; padding-left:4%;">
                  <span style="border:1px solid black;">
                    Ticket Number: ${obj.TicketData} 
                  </span> <br />
                  <p>
                  ${obj.FF_StateDisplay} &nbsp; ${obj.FF_CountyDisplay}<br />
                  ${obj.FF_FarmDisplay}
                  </p>
                </td>
            <tr>
          </table>
          <table style="align-items: center; width: ${tableWidth}%; font-size:${fontSize}%; font-family: arial;">
            <tr>
                <td style="width: 15%;">
                  Shipping Location:
                </td>
                <td style="width: 33%; border: 0; border-bottom: 1px solid #000;">
                  ${obj.Location_Number_1} - ${obj.Location_Name_1} &nbsp;
                </td>
                <td style="width: 5%;">
                  Address:
                </td>
                <td style="width: 23%; border: 0; border-bottom: 1px solid #000;">
                   ${obj.Location_Address_1} &nbsp;
                </td>
                <td style="width: 8%;">
                  Phone #
                </td>
                <td style="width: 17%; border: 0; border-bottom: 1px solid #000;">
                  ${obj.Location_Phone_1}
                </td>
            <tr>
          </table>
          <table style="align-items: center; width: ${tableWidth}%; font-size:${fontSize}%; font-family: arial;">
            <tr>
              <td style="width: 15%;">
                Receiving Location:
              </td>
              <td style="width: 33%; border: 0; border-bottom: 1px solid #000;">
                ${obj.Location_Number_2} - ${obj.Location_Name_2} &nbsp;
              </td>
              <td style="width: 5%;">
                Address:
              </td>
              <td style="width: 23%; border: 0; border-bottom: 1px solid #000;">
                ${obj.Location_Address_2} &nbsp;
              </td>
              <td style="width: 8%;">
                Phone #
              </td>
              <td style="width: 17%; border: 0; border-bottom: 1px solid #000;">
                ${obj.Location_Phone_2}
              </td>
            <tr>
          </table>
          <table style="align-items: center; width: ${tableWidth}%; font-size:${fontSize}%; font-family: arial;">
            <tr>
              <td style="width: 10%;">
                Truck Line: 
              </td>
              <td style="width: 17%; border: 0; border-bottom: 1px solid #000;">
                ${obj.TruckLine}
              </td>
              <td style="width: 5%;">
                Carrier:  
              </td>
              <td style="width: 15%; border: 0; border-bottom: 1px solid #000;">
                ${obj.mTransferStatus_SCAC}
              </td>
              <td style="width: 15%;">
                Trailer License No. 
              </td>
              <td style="width: 14%; border: 0; border-bottom: 1px solid #000;">
                ${obj.TrailerLicenseNumber}
              </td>
              <td style="width: 9%;">
                Dispatch #
              </td>
              <td style="width: 15%; border: 0; border-bottom: 1px solid #000;">
                 ${obj.DispatchNumber}
              </td>
            <tr>
          </table>
          <table style="min-height:3%; align-items: center; width: ${tableWidth}%; font-size:${fontSize}%; font-family: arial;">
            <tr>
              <td style="width: 6%;">
                Driver Name:
              </td>
              <td style="width: 10%; border: 0; border-bottom: 1px solid #000;">
                ${obj.DriversSignature}
              </td>
              <td style="width: 8%;">
                Driver's Signature:
              </td>
              <td style="width: 21%; border: 0; border-bottom: 1px solid #000;">
                
              </td>
              <td style="width: 2%;">
                1007 #
              </td>
              <td style="width: 8%; border: 0; border-bottom: 1px solid #000;">
                ${Settled_1007_Num}
              </td>
            <tr>
          </table>
          <table style="align-items: center; width: ${tableWidth}%; font-size:${fontSize}%; font-family: arial;">
            <tr>
              <td style="width: 50%; padding-left:0.5%; padding-right:1%; border:1px solid black;">
                <table style="font-family: arial; font-size:100%;">
                  <tr>
                    <td colspan="4">
                      <span style="border: 0; border-bottom: 1px solid #000;">
                        ${obj.ShippedHeader}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 3%;">
                      Whse#
                    </td>
                    <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                       ${obj.Shipped_Loaded_From_WarehouseNumber}
                    </td>
                    <td style="width: 2%;">
                      Peanut Type:
                    </td>
                    <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                      ${obj.Peanut_Type_Name}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 3%;">
                      Bin#
                    </td>
                    <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                      ${obj.Shipped_Loaded_From_BinNumber}
                    </td>
                    <td style="width: 2%;">
                      Variety:
                    </td>
                    <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                      ${obj.Shipped_Peanut_Variety_Name}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 4%;">
                      Scale Ticket#
                    </td>
                    <td style="width: 5%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                      ${obj.Shipped_ScaleTicketNumber}
                    </td>
                    <td style="width: 2%;">
                      Generation:
                    </td>
                    <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                      ${obj.Shipped_Peanut_Gen}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 3%;">
                      Gross Wt.
                    </td>
                    <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                      ${obj.Shipped_GrossWt_LBS} &nbsp; lbs.
                    </td>
                    <td style="width: 2%;">
                      Seg Type:
                    </td>
                    <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                      ${obj.Shipped_Peanut_Seg}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 3%;">
                      Tare Wt.
                    </td>
                    <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                      ${obj.Shipped_TareWt_LBS} &nbsp; lbs.
                    </td>
                    <td style="width: 2%;">
                      Oleic:
                    </td>
                    <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                      ${obj.Shipped_Oleic_Ind}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 3%;">
                      Net Wt.
                    </td>
                    <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                      ${obj.Shipped_NetWt_LBS} &nbsp; lbs.
                    </td>
                    <td style="width: 2%;">
                      Edible/Oil:
                    </td>
                    <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                      ${obj.Shipped_Edible_Oil_Ind}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 3%;">
                    </td>
                    <td style="width: 6%;">
                    </td>
                    <td style="width: 2%;">
                      Organic:
                    </td>
                    <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                      ${obj.From_Organic_Ind}
                    </td>
                  </tr>
                </table>
              </td>
              <td style="width: 50%; padding-left:0.5%; padding-right:1%; border:1px solid black;">
                <table style="font-family: arial; font-size:100%;">
                  <tr>
                    <td colspan="4">
                      <span style="border: 0; border-bottom: 1px solid #000;">
                        ${obj.ReceivedHeader}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 3%;">
                      Whse#
                    </td>
                    <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                      ${obj.Received_Loaded_To_WarehouseNumber}
                    </td>
                    <td style="width: 2%;">
                      Peanut Type:
                    </td>
                    <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                      ${obj.Peanut_Type_Name}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 3%;">
                      Bin#
                    </td>
                    <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                      ${obj.Received_Loaded_To_BinNumber}
                    </td>
                    <td style="width: 2%;">
                      Variety:
                    </td>
                    <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                      ${obj.Received_Peanut_Variety_Name}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 4%;">
                      Scale Ticket#
                    </td>
                    <td style="width: 5%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                      ${obj.Received_ScaleTicketNumber}
                    </td>
                    <td style="width: 2%;">
                      Generation:
                    </td>
                    <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                      ${obj.Received_Peanut_Gen}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 3%;">
                      Gross Wt.
                    </td>
                    <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                      ${obj.Received_GrossWt_LBS} &nbsp; lbs.
                    </td>
                    <td style="width: 2%;">
                      Seg Type:
                    </td>
                    <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                      ${obj.Received_Peanut_Seg}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 3%;">
                      Tare Wt.
                    </td>
                    <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                      ${obj.Received_TareWt_LBS} &nbsp; lbs.
                    </td>
                    <td style="width: 2%;">
                      Oleic:
                    </td>
                    <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                      ${obj.Received_Oleic_Ind}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 3%;">
                      Net Wt.
                    </td>
                    <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                      ${obj.Received_NetWt_LBS} &nbsp; lbs.
                    </td>
                    <td style="width: 2%;">
                      Edible/Oil:
                    </td>
                    <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                      ${obj.Received_Edible_Oil_Ind}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 3%;">
                    </td>
                    <td style="width: 6%;">
                    </td>
                    <td style="width: 2%;">
                      Organic:
                    </td>
                    <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                      ${obj.To_Organic_Ind}
                    </td>
                  </tr>
                </table>
              </td>
            <tr>
          </table>
          <table style="align-items: center; width: ${tableWidth}%; font-size:${fontSize}%; font-family: arial;">
            <tr style="height: 35px;">
              <td style="width: 70%; padding-left:0.5%; padding-right:1%; border:1px solid black;">
                Update Inventory With: &nbsp;&nbsp;&nbsp;${obj.Inventory_Ind} &nbsp;&nbsp; <u> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                ${obj.Other_Weight} lbs. </u>
              </td>
              <td style="width: 30%; padding-left:0.5%; padding-right:1%; border:1px solid black;">
                <span style="display: inline-block; width: 49%;">
                  Weight Variance:
                </span>
                <span style="display: inline-block; width: 49%; text-align: right;">
                  <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${obj.Received_WtVariance}&nbsp;lbs.</u>
                </span>
              </td>
            <tr>
          </table>
          <table style="align-items: center; margin-left: 2px; width: 99.6%; font-size:${fontSize}%; font-family: arial; border:1px solid black;">
            <tr>
              <td style="width: 3%;">
                FM Weight:
              </td>
              <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                ${obj.Received_FM_LBS}&nbsp;lbs.
              </td>
              <td style="width: 3%; padding-left: 1%;">
                Dollar Value:
              </td>
              <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                ${obj.Received_DollarValue}
              </td>
              <td style="width: 3%; padding-left: 1%;">
                Net Weight:
              </td>
              <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                ${obj.Received_FM_NetWt_LBS}&nbsp;lbs.
              </td>
            </tr>
            <tr>
              <td style="width: 3%;">
                Weight Less FM:
              </td>
              <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                ${obj.Received_WeightLessFM_LBS}&nbsp;lbs.
              </td>
              <td style="width: 5%; padding-left: 1%;">
                Freight - Rate/Ton:
              </td>
              <td style="width: 4%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                ${obj.Received_Freight_Rate_Per_Ton}
              </td>
              <td style="width: 3%; padding-left: 1%;">
                LSK Weight:
              </td>
              <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                ${obj.Received_LSK_LBS}&nbsp;lbs.
              </td>
            </tr>
            <tr>
              <td style="width: 3%;">
                Excess Moisture:
              </td>
              <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                ${obj.Received_ExcessMoisture_LBS}&nbsp;lbs.
              </td>
              <td style="width: 3%; padding-left: 1%;">
                Total Freight:
              </td>
              <td style="width: 6%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                $&nbsp;${obj.Received_TotalFreight_LBS}
              </td>
              <td style="width: 4%; padding-left: 1%;">
                Net-Less LSK:
              </td>
              <td style="width: 5%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                ${obj.Received_Net_Less_LSK_LBS}&nbsp;lbs.
              </td>
            </tr>
            <tr>
              <td colspan="6">
                &nbsp;
              </td>
            </tr>
            <tr>
              <td style="width: 5%;">
                Receiving Signature:
              </td>
              <td colspan="2" style="width: 2%; border: 0; border-bottom: 1px solid #000;">
              </td>
              <td style="width: 8%; padding-left: 1%;">
                Entered into Computer By:
              </td>
              <td colspan="2" style="width: 2%; border: 0; border-bottom: 1px solid #000; text-align: right;">
                ${obj.Entered_Into_Computer_By}
              </td>
            </tr>
          </table>
          <table style="align-items: center; width: ${tableWidth}%; font-size:62%; font-family: arial;">
            <tr>
              <td style="width: 30%; vertical-align: top;">
                <table style="margin-left: -1px; font-family: arial; font-size:100%;" class="footer-table">
                  <tr>
                    <td colspan="4" style="width: 3%;">
                      Unofficial Re-Grade
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="3" colspan="2" style="width: 3%;">
                      Weight of FM Samp <u>&nbsp;&nbsp;&nbsp;${obj.UR_WtOfFMSample_Gms.toFixed(1)}</u>&nbsp;g.
                    </td>
                    <td colspan="2" style="width: 2%;">
                      Clean Sample Wt.
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 2%;">
                      
                    </td>
                    <td style="width: 2%; text-align: right;">
                      500 g.
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 2%;">
                      
                    </td>
                    <td style="width: 2%; text-align: right;">
                      1000 g.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      SMKRS
                    </td>
                    <td style="width: 2%; text-align: right;">
                      ${obj.UR_SMKRS_gms.toFixed(1)}&nbsp;g.
                    </td>
                    <td style="width: 2%; text-align: right;">
                      ${obj.UR_SMKRS_per.toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      SMK 1 Riding
                    </td>
                    <td style="width: 2%; background-color: gray;">
                      
                    </td>
                    <td style="width: 2%; text-align: right;">
                      ${obj.UR_SMK_RidingScreen_1_Per.toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      SMK 2 Riding
                    </td>
                    <td style="width: 2%; background-color: gray;">
                      
                    </td>
                    <td style="width: 2%; text-align: right;">
                      ${obj.UR_SMK_RidingScreen_2_Per.toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      SMK 3 Riding
                    </td>
                    <td style="width: 2%; background-color: gray;">
                      
                    </td>
                    <td style="width: 2%; text-align: right;">
                      ${obj.UR_SMK_RidingScreen_3_Per.toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      SMK 4 Riding
                    </td>
                    <td style="width: 2%; background-color: gray;">
                      
                    </td>
                    <td style="width: 2%; text-align: right;">
                      ${obj.UR_SMK_RidingScreen_4_Per.toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      Sound Splits
                    </td>
                    <td style="width: 2%; text-align: right;">
                      ${obj.UR_SoundSplits_Gms.toFixed(1)}&nbsp;g.
                    </td>
                    <td style="width: 2%; text-align: right;">
                      ${obj.UR_SoundSplits_Per.toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      Total SMK
                    </td>
                    <td style="width: 2%; background-color: gray;">
                      
                    </td>
                    <td style="width: 2%; text-align: right;">
                      ${obj.UR_TotalSMK_Per.toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      OK
                    </td>
                    <td style="width: 2%; text-align: right;">
                      ${obj.UR_OK_Gms.toFixed(1)}&nbsp;g.
                    </td>
                    <td style="width: 2%; text-align: right;">
                      ${obj.UR_OK_Per.toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                    OK Ridding Screen
                    </td>
                    <td style="width: 2%; background-color: gray;">
                      
                    </td>
                    <td style="width: 2%; text-align: right;">
                      ${obj.UR_OK_RidingScreen_Per.toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                    OK Fall Through Screen
                    </td>
                    <td style="width: 2%; background-color: gray;">
                      
                    </td>
                    <td style="width: 2%; text-align: right;">
                      ${obj.UR_OK_FallThroughScreen_Per.toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      OK Damage Riding
                    </td>
                    <td style="width: 2%; background-color: gray;">
                      
                    </td>
                    <td style="width: 2%; text-align: right;">
                      ${obj.UR_OK_DamageRiding_Per.toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                </table>
              </td>
              <td style="width: 30%; vertical-align: top;">
                <table style="font-family: arial; font-size:100%;" class="footer-table">
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      Damage Splits
                    </td>
                    <td style="width: 2%; text-align: right;">
                      ${obj.UR_DamageSplits_Gms.toFixed(1)}&nbsp;g.
                    </td>
                    <td style="width: 2%; background-color: gray;">
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      Damage
                    </td>
                    <td style="width: 2%; text-align: right;">
                      ${obj.UR_Damage_Gms.toFixed(1)}&nbsp;g.
                    </td>
                    <td style="width: 2%; text-align: right;">
                      ${obj.UR_Damage_Per.toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      Total Kernals
                    </td>
                    <td style="width: 2%; background-color: gray;">
                      
                    </td>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_Total_Kernals_Per).toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      Hulls
                    </td>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_Total_Hulls_Gms).toFixed(1)}&nbsp;g.
                    </td>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_Total_Hulls_Per).toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      Total Kernals and Hulls
                    </td>
                    <td style="width: 2%; background-color: gray;">
                      
                    </td>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_Total_Kernals_Hulls).toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      Freeze Damage
                    </td>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_FreezeDamage_Gms).toFixed(1)}&nbsp;g.
                    </td>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_FreezeDamage_Per).toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      Pitting Damage
                    </td>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_PittingDamage_Gms).toFixed(1)}&nbsp;g.
                    </td>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_PittingDamage_Per).toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      Concealed RMD
                    </td>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_ConcealedRMD_Gms).toFixed(1)}&nbsp;g.
                    </td>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_ConcealedRMD_Per).toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="4" colspan="2" style="width: 2%;">
                      Valencia <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></br>
                      or</br>
                      Virginia<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></br>Type
                    </td>
                    <td colspan="2" style="width: 2%;">
                      Cracked or Broken
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_Valencia_Cracked_Broken_Gms).toFixed(1)}&nbsp;g.
                    </td>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_Valencia_Cracked_Broken_Per).toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      Discolored Shells
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_Virginia_DiscoloredShells_Gms).toFixed(1)}&nbsp;g.
                    </td>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_Virginia_DiscoloredShells_Per).toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                </table>
              </td>
              <td style="width: 30%; vertical-align: top;">
                <table style="font-family: arial; font-size:100%;" class="footer-table">
                  <tr>
                    <td rowspan="4" colspan="2" style="width: 2%;">
                      Virginia</br>
                      Type</br>
                      Only
                    </td>
                    <td colspan="2" style="width: 2%;">
                      Hull Brightness
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_Virginia_HullBrightness_Gms).toFixed(1)}&nbsp;g.
                    </td>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_Virginia_HullBrightness_Per).toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      Jumbo
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_Virginia_Jumbo_Gms).toFixed(1)}&nbsp;g.
                    </td>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_Virginia_Jumbo_Per).toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      ELK
                    </td>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_ELK_Gms).toFixed(1)}&nbsp;g.
                    </td>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_ELK_Per).toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      Foreign Material
                    </td>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_ForeignMaterial_Gms).toFixed(1)}&nbsp;g.
                    </td>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_ForeignMaterial_Per).toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      Total LSK
                    </td>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_Total_LSK_Gms).toFixed(1)}&nbsp;g.
                    </td>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_Total_LSK_Per).toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      +LSK Riding Screen
                    </td>
                    <td style="width: 2%; background-color: gray;">
                      
                    </td>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_LSK_RidingScreen_Per).toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      -LSK Fall Through
                    </td>
                    <td style="width: 2%; background-color: gray;">
                      
                    </td>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_LSK_FallThroughScreen_Per).toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      LSK Dam. Riding S
                    </td>
                    <td style="width: 2%; background-color: gray;">
                      
                    </td>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_LSK_DamageRidingScreen_Per).toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      Moisture Reading
                    </td>
                    <td colspan="2" style="width: 2%; text-align: right;">
                    ${Number(obj.UR_MoistureReading).toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      Fancy
                    </td>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_Fancy_Gms).toFixed(1)}&nbsp;g.
                    </td>
                    <td style="width: 2%; text-align: right;">
                    ${Number(obj.UR_Fancy_Per).toFixed(1)}&nbsp;%
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      A. Flavus Found</br>
                      (Seg. 3)
                    </td>
                    <td colspan="2" style="width: 2%;">
                    Yes <u>&nbsp;&nbsp;${obj.UR_FlavusFound_Yes}&nbsp;&nbsp;&nbsp;</u>&nbsp;No<u>&nbsp;&nbsp;${obj.UR_FlavusFound_No}&nbsp;&nbsp;&nbsp;</u>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 2%;">
                      Vicam Riding
                    </td>
                    <td colspan="2" style="width: 2%; text-align: right;">
                      ${obj.UR_VicamReading}&nbsp;ppb
                    </td>
                  </tr>
                </table>
              </td>
            <tr>
          </table>
          <table style="font-family: arial;width: 100%;border: none;">
            <tr style="width: 100%;border: none;">
              <td style="width: 100%;border: none;"><u><b>
                Remarks: </b></u>
              </td>
            </tr>
            <tr style="width: 100%;border: none;">
              <td style="width: 100%;border: none;">
                &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp${obj.Remarks}
              </td>
            </tr>
          </table>
          <table style="font-family: arial;width: 100%;height: 50px;border: none;">
            <tr style="width: 100%;border: none;text-align: center;">
              <td style="width: 100%;border: none;"><b>
              Trucker Copy</b>
              </td>
            </tr>
          </table>
          </p>`
        }

    return optionPage + `</html>`
  }

  // FA Settlement Report
  async function FASettlementData() {
    let rptPreviewTransferForFASettlement = getData(thisObj, 'ReportPreviewTransferForFASettlement');
    if (rptPreviewTransferForFASettlement !== null) {
      let rptMemoNumber = rptPreviewTransferForFASettlement.MemoNum;
      let rptType = rptPreviewTransferForFASettlement.ReportType;
      await rptFASettlement(rptPreviewTransferForFASettlement.data, rptMemoNumber, rptType);
      await ReadFileForFASettlement();
      //PRB0053955 - Stock transfer printing issue fixes
      setLoading(false); 
    }
  }

  async function rptFASettlement(data, memoNumber, rptType) {
    let dblFrtAmt = 0;
    let strPremDedCode = '';
    let dblPremDedAmt = 0;
    let strPremDedInd = '';
    let strPremDedRateType = '';
    let dblFuel = 0;
    let dblLease = 0;
    let dblMisc = 0;
    let dblTotal = 0;
    let intSignChange = 1;
    let LintPageCount = 0;

    setData(thisObj, 'mReportType', 'FreightAccural');
    setData(thisObj, 'msReport', 'FREIGHT_ACCURAL');
    
    let sVendorName = '';
    let LstrXML_SETTLE = data;
    LintPageCount = LintPageCount + 1;

    let vendorLists = await ContractManagementService.RetrieveVendorByNumber(LstrXML_SETTLE[0].frt_vendor);
    if (vendorLists.length > 0) {
      let vendorName = vendorLists[0].name;
      let vendorsArray = vendorName.split(' ');
      for(let i=0; i< vendorsArray.length; i++) {
        vendorsArray[i] = vendorsArray[i].charAt(0).toUpperCase() + vendorsArray[i].toLowerCase().slice(1);
      }
      vendorName = vendorsArray.join(' ');
      sVendorName = vendorName;
    }

    let strTripCurr = "";
    let strTripPrev = "";
    let dblTripFrtAmt = 0;
    let dblTripFuel = 0;
    let dblTripLease = 0;
    let dblTripMisc = 0;
    let dblTripTotal = 0;
    
    let dblGrandFrtAmt = 0;
    let dblGrandFuel = 0;
    let dblGrandLease = 0;
    let dblGrandMisc = 0;
    let dblGrandTotal = 0;
    let bFrtSettleFound = false;
    let intRecCount = 0;
    let row = '';
    let multiRow = '';
    let tripData1 = ''
    let tripData2 = ''
    let flag = true
    let lastPageFooter = ''
    let rowData = []
    let rowObj = {}

    for(let i =0; i < LstrXML_SETTLE.length; i++){
      let Strfreight_amt = '';
      let Strfrt_premium_amt = '';
      let Strfrt_deduct_amt = '';

      if (LstrXML_SETTLE[i].reversal_ind == "Y" && Number(LstrXML_SETTLE[i].accrual_amount) < 0) {
        Strfreight_amt = Number(LstrXML_SETTLE[i].freight_amt).toFixed(2) * -1;
        Strfrt_premium_amt = Number(LstrXML_SETTLE[i].frt_premium_amt) * -1
        Strfrt_deduct_amt = Number(LstrXML_SETTLE[i].frt_deduct_amt) * -1
      } else {
        Strfreight_amt = Number(LstrXML_SETTLE[i].freight_amt).toFixed(2);
        Strfrt_premium_amt = Number(LstrXML_SETTLE[i].frt_premium_amt);
        Strfrt_deduct_amt = Number(LstrXML_SETTLE[i].frt_deduct_amt);
      }
      dblFrtAmt = Strfreight_amt;

      let sPD = LstrXML_SETTLE[i].transfer_pds;
      strPremDedCode = "";
      dblPremDedAmt = 1;
      strPremDedInd = ""; 
      strPremDedRateType = "";
      dblFuel = 0;
      dblLease = 0;
      dblMisc = 0;
      dblTotal = 0;
      if(LstrXML_SETTLE[i].reversal_ind == 'Y' && isNaN(LstrXML_SETTLE[i].accrual_amount) == false && (Number(LstrXML_SETTLE[i].accrual_amount) < 0)){
        intSignChange = -1
      }
      else{
        intSignChange = 1
      }

      if (sPD.length > 0) {
        for(let pds = 0; pds < sPD.length; pds++) {
          if (LstrXML_SETTLE[i].transfer_num === sPD[pds].xfer_num) {
            strPremDedCode = sPD[pds].pd_code;
            dblPremDedAmt = sPD[pds].pd_amount * intSignChange;
            strPremDedInd = sPD[pds].pd_ind;
            strPremDedRateType = sPD[pds].rate_type;

            if (strPremDedRateType.toUpperCase() === "FUEL") {
              dblFuel = dblFuel + dblPremDedAmt;
            } else if (strPremDedRateType.toUpperCase() === "LEASE") {
              dblLease = dblLease + dblPremDedAmt;
            } else {
              dblMisc = dblMisc + dblPremDedAmt;
            }
          }
        }
      }
      dblTotal = Number(dblFrtAmt) + Number(dblFuel) + Number(dblLease) + Number(dblMisc);

      let weightTicket = '';
      if (LstrXML_SETTLE[i].pay_ind === "R") {
        weightTicket = LstrXML_SETTLE == undefined || LstrXML_SETTLE == null || LstrXML_SETTLE == '' || LstrXML_SETTLE[i].rec_scale_tick == undefined || LstrXML_SETTLE[i].rec_scale_tick == null || LstrXML_SETTLE[i].rec_scale_tick == '' ? '' : LstrXML_SETTLE[i].rec_scale_tick;
      } else if (LstrXML_SETTLE[i].pay_ind === "S") {
        weightTicket = LstrXML_SETTLE == undefined || LstrXML_SETTLE == null || LstrXML_SETTLE == '' || LstrXML_SETTLE[i].shp_scale_tick == undefined || LstrXML_SETTLE[i].shp_scale_tick == null || LstrXML_SETTLE[i].shp_scale_tick == '' ? '' : LstrXML_SETTLE[i].shp_scale_tick;
      } else {
        weightTicket = "N/A";
      }
      rowObj.trip_id = LstrXML_SETTLE[i].trip_id
      rowObj.transfer_num = LstrXML_SETTLE[i].transfer_num
      rowObj.shp_buy_pt = LstrXML_SETTLE[i].shp_buy_pt
      rowObj.rec_buy_pt = LstrXML_SETTLE[i].rec_buy_pt
      rowObj.trip_weight = LstrXML_SETTLE[i].trip_weight > 0 ? LstrXML_SETTLE[i].trip_weight : LstrXML_SETTLE[i].other_wt !== '' ? LstrXML_SETTLE[i].other_wt : LstrXML_SETTLE[i].weight
      rowObj.freight_rate = Number(LstrXML_SETTLE[i].freight_rate).toFixed(2)
      rowObj.Strfreight_amt = Strfreight_amt
      rowObj.dblFuel = dblFuel.toFixed(2)
      rowObj.dblLease = dblLease.toFixed(2)
      rowObj.dblMisc = dblMisc.toFixed(2)
      rowObj.dblTotal = dblTotal.toFixed(2)
      rowObj.shp_bp_city_st = LstrXML_SETTLE[i].shp_bp_city_st
      rowObj.rec_bp_city_st = LstrXML_SETTLE[i].rec_bp_city_st
      rowObj.weightTicket = weightTicket
      rowObj.driver_name = LstrXML_SETTLE[i].driver_name
      rowObj.crop_year = LstrXML_SETTLE[i].crop_year
      rowData.push(rowObj)
      rowObj = {}
    }
    
    for (let i = 0; i < LstrXML_SETTLE.length; i++) {
      let Strfreight_amt = '';
      let Strfrt_premium_amt = '';
      let Strfrt_deduct_amt = '';
      
      bFrtSettleFound = true;
      intRecCount = intRecCount + 1;
      strTripCurr = LstrXML_SETTLE[i].trip_id;
      if (strTripPrev !== strTripCurr) {
        if (intRecCount > 1) {
          multiRow += 
          `
          <tr style="font-weight: bold; text-decoration: underline;">
            <td style="width:44%;text-align:right;"></td>
            <td style="width:20%; text-align:right;"> Multi-Stop Trip Total: </td>
            <td style="width:10%; text-align:right;"> ${dblTripFrtAmt.toFixed(2)} </td>
            <td style="width:6%; text-align:right;"> ${dblTripFuel.toFixed(2)} </td>
            <td style="width:6%; text-align:right;"> ${dblTripLease.toFixed(2)} </td>
            <td style="width:7%; text-align:right;"> ${dblTripMisc.toFixed(2)} </td>
            <td style="width:7%; text-align:right;"> ${dblTripTotal.toFixed(2)} </td>
          </tr>
          `;
          dblTripFrtAmt = 0;
          dblTripFuel = 0;
          dblTripLease = 0;
          dblTripMisc = 0;
          dblTripTotal = 0;
        }
      }
      strTripPrev = strTripCurr;
      if (LstrXML_SETTLE[i].reversal_ind == "Y" && Number(LstrXML_SETTLE[i].accrual_amount) < 0) {
        Strfreight_amt = Number(LstrXML_SETTLE[i].freight_amt).toFixed(2) * -1;
        Strfrt_premium_amt = Number(LstrXML_SETTLE[i].frt_premium_amt) * -1
        Strfrt_deduct_amt = Number(LstrXML_SETTLE[i].frt_deduct_amt) * -1
      } else {
        Strfreight_amt = Number(LstrXML_SETTLE[i].freight_amt).toFixed(2);
        Strfrt_premium_amt = Number(LstrXML_SETTLE[i].frt_premium_amt);
        Strfrt_deduct_amt = Number(LstrXML_SETTLE[i].frt_deduct_amt);
      }
      dblFrtAmt = Strfreight_amt;

      strPremDedCode = "";
      dblPremDedAmt = 1;
      strPremDedInd = ""; 
      strPremDedRateType = "";
      dblFuel = 0;
      dblLease = 0;
      dblMisc = 0;
      dblTotal = 0;

      let sPD = LstrXML_SETTLE[i].transfer_pds;
      if(LstrXML_SETTLE[i].reversal_ind == 'Y' && isNaN(LstrXML_SETTLE[i].accrual_amount) == false && (Number(LstrXML_SETTLE[i].accrual_amount) < 0)){
        intSignChange = -1
      }
      else{
        intSignChange = 1
      }
      if (sPD.length > 0) {
        for(let pds = 0; pds < sPD.length; pds++) {
          if (LstrXML_SETTLE[i].transfer_num === sPD[pds].xfer_num) {
            strPremDedCode = sPD[pds].pd_code;
            dblPremDedAmt = sPD[pds].pd_amount * intSignChange;
            strPremDedInd = sPD[pds].pd_ind;
            strPremDedRateType = sPD[pds].rate_type;

            if (strPremDedRateType.toUpperCase() === "FUEL") {
              dblFuel = dblFuel + dblPremDedAmt;
            } else if (strPremDedRateType.toUpperCase() === "LEASE") {
              dblLease = dblLease + dblPremDedAmt;
            } else {
              dblMisc = dblMisc + dblPremDedAmt;
            }
          }
        }
      }

      dblTotal = Number(dblFrtAmt) + Number(dblFuel) + Number(dblLease) + Number(dblMisc);

      dblTripFrtAmt = Number(dblTripFrtAmt) + Number(dblFrtAmt);
      dblTripFuel = Number(dblTripFuel) + Number(dblFuel);
      dblTripLease = Number(dblTripLease) + Number(dblLease);
      dblTripMisc = Number(dblTripMisc) + Number(dblMisc);
      dblTripTotal = Number(dblTripTotal) + Number(dblTotal);

      dblGrandFrtAmt = Number(dblGrandFrtAmt) + Number(dblFrtAmt);
      dblGrandFuel = Number(dblGrandFuel) + Number(dblFuel);
      dblGrandLease = Number(dblGrandLease) + Number(dblLease);
      dblGrandMisc = Number(dblGrandMisc) + Number(dblMisc);
      dblGrandTotal = Number(dblGrandTotal) +Number( dblTotal);
      
      let weightTicket = '';
      if (LstrXML_SETTLE[i].pay_ind === "R") {
        weightTicket = LstrXML_SETTLE == undefined || LstrXML_SETTLE == null || LstrXML_SETTLE == '' || LstrXML_SETTLE[i].rec_scale_tick == undefined || LstrXML_SETTLE[i].rec_scale_tick == null || LstrXML_SETTLE[i].rec_scale_tick == '' ? '' : Number(LstrXML_SETTLE[i].rec_scale_tick);
      } else if (LstrXML_SETTLE[i].pay_ind === "S") {
        weightTicket = LstrXML_SETTLE == undefined || LstrXML_SETTLE == null || LstrXML_SETTLE == '' || LstrXML_SETTLE[i].shp_scale_tick == undefined || LstrXML_SETTLE[i].shp_scale_tick == null || LstrXML_SETTLE[i].shp_scale_tick == '' ? '' : Number(LstrXML_SETTLE[i].shp_scale_tick);
      } else {
        weightTicket = "N/A";
      }
    }
    
    if (bFrtSettleFound) {
      if (strTripPrev !== '') {
        multiRow +=
          `
          <tr style="font-weight: bold; text-decoration: underline;">
            <td style="width:44%;text-align:right;"></td>        
            <td style="width:20%;text-align:right;"> Multi-Stop Trip Total: </td>
            <td style="width:10%;text-align:right;"> ${dblTripFrtAmt.toFixed(2)} </td>
            <td style="width:6%;text-align:right;"> ${dblTripFuel.toFixed(2)} </td>
            <td style="width:6%;text-align:right;"> ${dblTripLease.toFixed(2)} </td>
            <td style="width:7%;text-align:right;"> ${dblTripMisc.toFixed(2)} </td>
            <td style="width:7%;text-align:right;"> ${dblTripTotal.toFixed(2)} </td>
          </tr>
          `;
      }
      multiRow +=
        `
        <tr style="font-weight: bold; text-decoration: underline;">
          <td style="width:44%;text-align:right;"></td>
          <td style="width:20%;text-align:right;" > Grand Total: </td>
          <td style="width:10%;text-align:right;" > ${dblGrandFrtAmt.toFixed(2)} </td>
          <td style="width:6%;text-align:right;" > ${dblGrandFuel.toFixed(2)} </td>
          <td style="width:6%;text-align:right;" > ${dblGrandLease.toFixed(2)} </td>
          <td style="width:7%;text-align:right;" > ${dblGrandMisc.toFixed(2)} </td>
          <td style="width:7%;text-align:right;" > ${dblGrandTotal.toFixed(2)} </td>
        </tr>
        `;
    }

    multiRow = `<table class="settlement-table" style="margin-top:2%;">` + multiRow + `</table>`
    
    let objFAData = {
      rowData:rowData,
      ReportType: rptType,
      VendorName: sVendorName,
      Date: LstrXML_SETTLE[0].date,
      // Date: moment(new Date()).format('MM/DD/YYYY hh:mm:ss A'),
      sVendor: LstrXML_SETTLE[0].frt_vendor + ' - ' + sVendorName,
      sInvoice: LstrXML_SETTLE[0].invoice,
      FooterVendor: 'Vendor: ' + LstrXML_SETTLE[0].frt_vendor + ' - ' + sVendorName,
      FooterInvoice: 'Invoice: ' + LstrXML_SETTLE[0].invoice, 
      LastPageFooter : lastPageFooter,
      Memo: memoNumber,
      MultipStopTripRow: multiRow,
    }

    setData(thisObj, 'rptFAData', objFAData);
  }

  async function ReadFileForFASettlement() {
    let renderCode = await GetHtmlCodeForFASettlement(90, 100, '')
    state.renderCode = renderCode
    state.renderUrl = getBlobURL(renderCode, 'text/html')
    document.getElementById("docFrame").setAttribute("src", state.renderUrl);
  }

  async function GetHtmlCodeForFASettlement(fontSize, tableWidth, pageGap) {
    let obj = getData(thisObj, 'rptFAData');
    let renderCode = printRptDataForFASettlement(fontSize, tableWidth, pageGap, obj);
    return renderCode;
  }

  async function printRptDataForFASettlement_old(fontSize, tableWidth, pageGap, obj) {
    let environmentType = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test';
    let Count = 1
    let End = 17
    let pageBreak = '';
    pageBreak += `<p style="page-break-after: always"></p>`

    let optionPage = `<html>
    <style>
        @media print {
            @page :footer { display: none }    
            @page :header { display: none }    
            @page { margin-top: 0; margin-bottom: 0; }    
            html,
            body { height: 100%; margin: 0 !important; padding: 0 !important; }
        }
        .before {
          font-family: sans-serif;
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          color: darkslategrey;
          font-size: 200px;
          opacity: 0.35;
          text-align: center;
        }
        .settlement-table {
          font-size: 13px;
          font-family: arial;
          width: 100%;
          border-collapse: collapse;
          white-space: pre-line; 
        }
        </style>        
        <table style="align-items: center; width: ${tableWidth}%; font-size:${fontSize}%; font-family: arial;padding-top:3.5%;">
          <div class="before">
            <span>${environmentType}</br><u>${obj.ReportType}</u></span>
          </div>
          <tr>
              <td style="width: 30%;">
                &nbsp;
              </td>
              <td style="width: 30%; padding-left:0.1%; text-align: center; padding-right: 0.1%;">
                <span style="font-size:150%; color: black;">
                  <b><u>Freight Settlement Document</u></b> <br /><br /><br />
                </span>
                <span style="font-size:150%; color: black;"><b>${obj.Date}</b></span>
              </td>
              <td style="width: 30%; padding-left:4%; text-align: right;">
                  <span style="font-size: 165%"><b>Golden Peanut Company, LLC</b><br />
                  <b>(Golden / Handler)</b> <br />
                  <b>100 North Point Center East, Suite 400</b> <br />
                  <b>Alpharetta, Georgia</b> </span>
              </td>
          </tr>
        </table>
        <table style="align-items: center; width: ${tableWidth}%; font-size:${fontSize}%; font-family: arial;">
          <tr>
              <td style="text-align: left; font-size: 160%;">
                Vendor: ${obj.sVendor} </br>
                Memo: ${obj.Memo} </br>
                Invoice: ${obj.sInvoice}
              </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
        </table>
        `

        for(let i = 0; i < obj?.rowData?.length; i++){
          if(Count == 1){
            if(i == 0){
              optionPage += `
              <table class="settlement-table">
                <tr style="text-decoration: underline;">
                  <th style="width:5%; text-align: left;" >Trip</th>
                  <th style="width:8%; text-align: left;" >Transfer</th>
                  <th style="width:20%; text-align: left;" >Origination</th>
                  <th style="width:19%; text-align: left;" >Destination</th>
                  <th style="width:6%; text-align: right;" >Weight</th>
                  <th style="width:6%; text-align: right;" >Rate</th>
                  <th style="width:10%; text-align: right;" >Freight Amt</th>
                  <th style="width:6%; text-align: right;" >Fuel</th>
                  <th style="width:6%; text-align: right;" >Lease</th>
                  <th style="width:7%; text-align: right;" >Misc</th>
                  <th style="width:7%; text-align: right;" >Total</th>
                </tr>
                <tr>
                  <td style="width:5%; text-align: left;" > ${obj.rowData[i].trip_id} </td>
                  <td style="width:8%; text-align: left;" > ${obj.rowData[i].transfer_num} </td>
                  <td style="width:20%; text-align: left;" > ${obj.rowData[i].shp_buy_pt} </td>
                  <td style="width:19%; text-align: left;" > ${obj.rowData[i].rec_buy_pt} </td>
                  <td style="width:6%; text-align: right;" > ${obj.rowData[i].trip_weight} </td>
                  <td style="width:6%; text-align: right;" > ${obj.rowData[i].freight_rate} </td>
                  <td style="width:10%; text-align: right;" > ${obj.rowData[i].Strfreight_amt} </td>
                  <td style="width:6%; text-align: right;" > ${obj.rowData[i].dblFuel} </td>
                  <td style="width:6%; text-align: right;" > ${obj.rowData[i].dblLease} </td>
                  <td style="width:7%; text-align: right;" > ${obj.rowData[i].dblMisc} </td>
                  <td style="width:7%; text-align: right;" > ${obj.rowData[i].dblTotal} </td>
                </tr>
              <tr>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> ${obj.rowData[i].shp_bp_city_st} </td>
                <td> ${obj.rowData[i].rec_bp_city_st} </td>
                <td colspan='3'> Weight Ticket:  ${obj.rowData[i].weightTicket} </td>
                <td colspan='2'> Driver:  ${obj.rowData[i].driver_name} </td>
                <td colspan='2'> Crop Year:  ${obj.rowData[i].crop_year} </td>
              </tr>
              <tr> <td> </br> </td> </tr>`;
            }
            else if(i == 8){
              optionPage += `
                </table>
                </br>
                <table style="width:100%;" class="footer-table">
                  <tr>
                    <td style="width: 45%">${obj.FooterVendor}</td>
                    <td style="width: 45%">${obj.FooterInvoice}</td>
                    <td style="width: 10%">Page: ${Count} </td>
                  </tr>
                </table>
                ${pageBreak}
                </br>
                </br>
                <table class="settlement-table">
                  <tr style="text-decoration: underline;">
                    <th style="width:5%; text-align: left;" >Trip</th>
                    <th style="width:8%; text-align: left;" >Transfer</th>
                    <th style="width:20%; text-align: left;" >Origination</th>
                    <th style="width:19%; text-align: left;" >Destination</th>
                    <th style="width:6%; text-align: right;" >Weight</th>
                    <th style="width:6%; text-align: right;" >Rate</th>
                    <th style="width:10%; text-align: right;" >Freight Amt</th>
                    <th style="width:6%; text-align: right;" >Fuel</th>
                    <th style="width:6%; text-align: right;" >Lease</th>
                    <th style="width:7%; text-align: right;" >Misc</th>
                    <th style="width:7%; text-align: right;" >Total</th>
                  </tr>
                  <tr>
                    <td style="width:5%; text-align: left;" > ${obj.rowData[i].trip_id} </td>
                    <td style="width:8%; text-align: left;" > ${obj.rowData[i].transfer_num} </td>
                    <td style="width:20%; text-align: left;" > ${obj.rowData[i].shp_buy_pt} </td> 
                    <td style="width:19%; text-align: left;" > ${obj.rowData[i].rec_buy_pt} </td>
                    <td style="width:6%; text-align: right;" > ${obj.rowData[i].trip_weight} </td>
                    <td style="width:6%; text-align: right;" > ${obj.rowData[i].freight_rate} </td>
                    <td style="width:10%; text-align: right;" > ${obj.rowData[i].Strfreight_amt} </td>
                    <td style="width:6%; text-align: right;" > ${obj.rowData[i].dblFuel} </td>
                    <td style="width:6%; text-align: right;" > ${obj.rowData[i].dblLease} </td>
                    <td style="width:7%; text-align: right;" > ${obj.rowData[i].dblMisc} </td>
                    <td style="width:7%; text-align: right;" > ${obj.rowData[i].dblTotal} </td>
                  </tr>
                  <tr>
                    <td> &nbsp; </td>
                    <td> &nbsp; </td>
                    <td> ${obj.rowData[i].shp_bp_city_st} </td>
                    <td> ${obj.rowData[i].rec_bp_city_st} </td>
                    <td colspan='3'> Weight Ticket:  ${obj.rowData[i].weightTicket} </td>
                    <td colspan='2'> Driver:  ${obj.rowData[i].driver_name} </td>
                    <td colspan='2'> Crop Year:  ${obj.rowData[i].crop_year} </td>
                  </tr>
                  <tr> <td> </br> </td> </tr>`;

              Count = Count + 1
            }
            else if(i <= 7){
              optionPage += `
                <tr>
                  <td style="width:5%; text-align: left;"> ${obj.rowData[i].trip_id} </td>
                  <td style="width:8%; text-align: left;"> ${obj.rowData[i].transfer_num} </td>
                  <td style="width:20%; text-align: left;"> ${obj.rowData[i].shp_buy_pt} </td>
                  <td style="width:19%; text-align: left;"> ${obj.rowData[i].rec_buy_pt} </td>
                  <td style="width:6%; text-align: right;"> ${obj.rowData[i].trip_weight} </td>
                  <td style="width:6%; text-align: right;"> ${obj.rowData[i].freight_rate} </td>
                  <td style="width:10%; text-align: right;"> ${obj.rowData[i].Strfreight_amt} </td>
                  <td style="width:6%; text-align: right;"> ${obj.rowData[i].dblFuel} </td>
                  <td style="width:6%; text-align: right;"> ${obj.rowData[i].dblLease} </td>
                  <td style="width:7%; text-align: right;"> ${obj.rowData[i].dblMisc} </td>
                  <td style="width:7%; text-align: right;"> ${obj.rowData[i].dblTotal} </td>
              </tr>
              <tr>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> ${obj.rowData[i].shp_bp_city_st} </td>
                <td> ${obj.rowData[i].rec_bp_city_st} </td>
                <td colspan='3'> Weight Ticket:  ${obj.rowData[i].weightTicket} </td>
                <td colspan='2'> Driver:  ${obj.rowData[i].driver_name} </td>
                <td colspan='2'> Crop Year:  ${obj.rowData[i].crop_year} </td>
              </tr>
              <tr> <td> </br> </td> </tr>`
            }
          }
          else{
            if(i == End + 1){
              optionPage += `
                </table>
                </br>
                <table style="width:100%;" class="footer-table">
                  <tr>
                    <td style="width: 45%">${obj.FooterVendor}</td>
                    <td style="width: 45%">${obj.FooterInvoice}</td>
                    <td style="width: 10%">Page: ${Count} </td>
                  </tr>
                </table>
                ${pageBreak}
                </br>
                </br>
                <table class="settlement-table">
                  <tr style="text-decoration: underline;">
                    <th style="width:5%; text-align: left;" >Trip</th>
                    <th style="width:8%; text-align: left;" >Transfer</th>
                    <th style="width:20%; text-align: left;" >Origination</th>
                    <th style="width:19%; text-align: left;" >Destination</th>
                    <th style="width:6%; text-align: right;" >Weight</th>
                    <th style="width:6%; text-align: right;" >Rate</th>
                    <th style="width:10%; text-align: right;" >Freight Amt</th>
                    <th style="width:6%; text-align: right;" >Fuel</th>
                    <th style="width:6%; text-align: right;" >Lease</th>
                    <th style="width:7%; text-align: right;" >Misc</th>
                    <th style="width:7%; text-align: right;" >Total</th>
                  </tr>
                  <tr>
                    <td style="width:5%; text-align: left;"> ${obj.rowData[i].trip_id} </td>
                    <td style="width:8%; text-align: left;"> ${obj.rowData[i].transfer_num} </td>
                    <td style="width:20%; text-align: left;"> ${obj.rowData[i].shp_buy_pt} </td>
                    <td style="width:19%; text-align: left;"> ${obj.rowData[i].rec_buy_pt} </td>
                    <td style="width:6%; text-align: right;"> ${obj.rowData[i].trip_weight} </td>
                    <td style="width:6%; text-align: right;"> ${obj.rowData[i].freight_rate} </td>
                    <td style="width:10%; text-align: right;"> ${obj.rowData[i].Strfreight_amt} </td>
                    <td style="width:6%; text-align: right;"> ${obj.rowData[i].dblFuel} </td>
                    <td style="width:6%; text-align: right;"> ${obj.rowData[i].dblLease} </td>
                    <td style="width:7%; text-align: right;"> ${obj.rowData[i].dblMisc} </td>
                    <td style="width:7%; text-align: right;"> ${obj.rowData[i].dblTotal} </td>
                  </tr>
                  <tr>
                    <td> &nbsp; </td>
                    <td> &nbsp; </td>
                    <td> ${obj.rowData[i].shp_bp_city_st} </td>
                    <td> ${obj.rowData[i].rec_bp_city_st} </td>
                    <td colspan='3'> Weight Ticket:  ${obj.rowData[i].weightTicket} </td>
                    <td colspan='2'> Driver:  ${obj.rowData[i].driver_name} </td>
                    <td colspan='2'> Crop Year:  ${obj.rowData[i].crop_year} </td>
                  </tr>
                  <tr> <td> </br> </td> </tr>`;
  
              Count = Count + 1
              End = End + 9
            }
            else if(i <= End){
              optionPage += `
                <tr>
                  <td style="width:5%; text-align: left;"> ${obj.rowData[i].trip_id} </td>
                  <td style="width:8%; text-align: left;"> ${obj.rowData[i].transfer_num} </td>
                  <td style="width:20%; text-align: left;"> ${obj.rowData[i].shp_buy_pt} </td>
                  <td style="width:19%; text-align: left;"> ${obj.rowData[i].rec_buy_pt} </td>
                  <td style="width:6%; text-align: right;"> ${obj.rowData[i].trip_weight} </td>
                  <td style="width:6%; text-align: right;"> ${obj.rowData[i].freight_rate} </td>
                  <td style="width:10%; text-align: right;"> ${obj.rowData[i].Strfreight_amt} </td>
                  <td style="width:6%; text-align: right;"> ${obj.rowData[i].dblFuel} </td>
                  <td style="width:6%; text-align: right;"> ${obj.rowData[i].dblLease} </td>
                  <td style="width:7%; text-align: right;"> ${obj.rowData[i].dblMisc} </td>
                  <td style="width:7%; text-align: right;"> ${obj.rowData[i].dblTotal} </td>
                </tr>
                <tr>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> ${obj.rowData[i].shp_bp_city_st} </td>
                  <td> ${obj.rowData[i].rec_bp_city_st} </td>
                  <td colspan='3'> Weight Ticket:  ${obj.rowData[i].weightTicket} </td>
                  <td colspan='2'> Driver:  ${obj.rowData[i].driver_name} </td>
                  <td colspan='2'> Crop Year:  ${obj.rowData[i].crop_year} </td>
                </tr>
                <tr> <td> </br> </td> </tr>`
            }
          }
        }

        let FirstFooterAlignment = '</br></br></br></br></br>'
        let FooterAlignment = ''

        if(obj?.rowData?.length < 8){
          for(let i = 0; i < (8 - obj?.rowData?.length); i++){
            FirstFooterAlignment += `</br></br></br></br></br>`
          }
          optionPage += `
          </table>
          ${obj.MultipStopTripRow}
          ${FirstFooterAlignment}
          <table style="width:100%;" class="footer-table">
            <tr>
              <td style="width: 45%">${obj.FooterVendor}</td>
              <td style="width: 45%">${obj.FooterInvoice}</td>
              <td style="width: 10%">Page: ${Count} </td>
            </tr>
          </table>
          `
        }
        else if(obj?.rowData?.length == 8){
          optionPage += `
          </table>
          ${obj.MultipStopTripRow}
          </br>
          </br>
          <table style="width:100%;" class="footer-table">
            <tr>
              <td style="width: 45%">${obj.FooterVendor}</td>
              <td style="width: 45%">${obj.FooterInvoice}</td>
              <td style="width: 10%">Page: ${Count} </td>
            </tr>
          </table>
          `
        }
        else if(obj?.rowData?.length == End){
          optionPage += `
          </table>
          ${obj.MultipStopTripRow}
          </br>
          </br>
          <table style="width:100%;" class="footer-table">
            <tr>
              <td style="width: 45%">${obj.FooterVendor}</td>
              <td style="width: 45%">${obj.FooterInvoice}</td>
              <td style="width: 10%">Page: ${Count} </td>
            </tr>
          </table>
          `
        }
        else{
          for(let i = 0; i < (End - (obj?.rowData?.length)); i++){
            FooterAlignment += `</br></br></br></br></br>`
          }
          optionPage += `
          </table>
          ${obj.MultipStopTripRow}
          ${FooterAlignment}
          <table style="width:100%;" class="footer-table">
            <tr>
              <td style="width: 45%">${obj.FooterVendor}</td>
              <td style="width: 45%">${obj.FooterInvoice}</td>
              <td style="width: 10%">Page: ${Count} </td>
            </tr>
          </table>
          `
        }

    return optionPage + `</html>`
  }
  async function printRptDataForFASettlement(fontSize, tableWidth, pageGap, obj) {
    let environmentType = process.env.REACT_APP_ENVIR == 'PROD' ? '' : 'Test';
    let Count = 1
    let End = 17
    let pageBreak = '';
    let PageRecordLimit=7;
    pageBreak += `<p style="page-break-after: always"></p>`

    let optionPage = `<html>
    <style>
        @media print {
            @page :footer { display: none; }    
            @page :header { display: none }        
            @page { margin-top: 0; margin-bottom: 0;}    
            html,
            body { height: 100%; margin: 0 !important; padding: 0 !important;  }
            
        }
        html,
        body {
      
          padding:0;
          height:100%;
        }
        #container {
          min-height:90%;
          position:relative;
        }
        #header {
          
          padding:0px;
        }
        #body {
          padding:10px;
          padding-bottom:60px;	/* Height of the footer */
        }
        #footer {
          position:absolute;
          bottom:0px;
          width:100%;
          height:5px;			/* Height of the footer */
         
        }
        /* other non-essential CSS */
        #footer h2 {
          margin:0;
          padding:60px;
        }
       
        .before {
          font-family: sans-serif;
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          color: darkslategrey;
          font-size: 200px;
          opacity: 0.35;
          text-align: center;
        }
        .settlement-table {
          font-size: 12px;
          font-family: arial;
          width: 100%;
          border-collapse: collapse;
          white-space: pre-line; 
        }
        </style>    
        <div id="container">
        <div id="header">
        <table style="align-items: center; width: ${tableWidth}%; font-size:${fontSize}%; font-family: arial;padding-top:3.5%;">
          <div class="before">
            <span>${environmentType}</br><u>${obj.ReportType}</u></span>
          </div>
          <tr>
              <td style="width: 30%;">
                &nbsp;
              </td>
              <td style="width: 30%; padding-left:0.1%; text-align: center; padding-right: 0.1%;">
                <span style="font-size:150%; color: black;">
                  <b><u>Freight Settlement Document</u></b> <br /><br /><br />
                </span>
                <span style="font-size:150%; color: black;"><b>${obj.Date}</b></span>
              </td>
              <td style="width: 30%; padding-left:4%; text-align: right;">
                  <span style="font-size: 150%"><b>Golden Peanut Company, LLC</b><br />
                  <b>(Golden / Handler)</b> <br />
                  <b>100 North Point Center East, Suite 400</b> <br />
                  <b>Alpharetta, Georgia</b> </span>
              </td>
          </tr>
        </table>
        <table style="align-items: center; width: ${tableWidth}%; font-size:${fontSize}%; font-family: arial;">
          <tr>
              <td style="text-align: left; font-size: 150%;">
                Vendor: ${obj.sVendor} </br>
                Memo: ${obj.Memo} </br>
                Invoice: ${obj.sInvoice}
              </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
        </table>
        </div>
        `
        optionPage += `
        <div id="body">
        <table  id="settlementtable" class="settlement-table">
  
          <tr style="text-decoration: underline;">
            <th style="width:5%; text-align: left;" >Trip</th>
            <th style="width:8%; text-align: left;" >Transfer</th>
            <th style="width:20%; text-align: left;" >Origination</th>
            <th style="width:19%; text-align: left;" >Destination</th>
            <th style="width:6%; text-align: right;" >Weight</th>
            <th style="width:6%; text-align: right;" >Rate</th>
            <th style="width:10%; text-align: right;" >Freight Amt</th>
            <th style="width:6%; text-align: right;" >Fuel</th>
            <th style="width:6%; text-align: right;" >Lease</th>
            <th style="width:7%; text-align: right;" >Misc</th>
            <th style="width:7%; text-align: right;" >Total</th>
          </tr>
  `
        for(let i = 0; i < obj?.rowData?.length; i++){

          
          if (i % PageRecordLimit === 0 && i !== 0) {
            optionPage += `</table></div>
            <div id="footer">
            <table style="width:100%;" class="footer-table" style="background:red">
              <tr>
                <td style="width: 45%">${obj.FooterVendor}</td>
                <td style="width: 45%">${obj.FooterInvoice}</td>
                <td style="width: 10%">Page: ${Count} </td>
              </tr>
            </table>
            </div>
            </div>
           ${pageBreak}
            <div id="container">
                       <div id="body">
        <table  class="settlement-table">
  
          <tr style="text-decoration: underline;">
            <th style="width:5%; text-align: left;" >Trip</th>
            <th style="width:8%; text-align: left;" >Transfer</th>
            <th style="width:20%; text-align: left;" >Origination</th>
            <th style="width:19%; text-align: left;" >Destination</th>
            <th style="width:6%; text-align: right;" >Weight</th>
            <th style="width:6%; text-align: right;" >Rate</th>
            <th style="width:10%; text-align: right;" >Freight Amt</th>
            <th style="width:6%; text-align: right;" >Fuel</th>
            <th style="width:6%; text-align: right;" >Lease</th>
            <th style="width:7%; text-align: right;" >Misc</th>
            <th style="width:7%; text-align: right;" >Total</th>
          </tr>
              `
              Count = Count + 1
          }
              optionPage += `
                              <tr>
                  <td style="width:5%; text-align: left;" > ${obj.rowData[i].trip_id} </td>
                  <td style="width:8%; text-align: left;" > ${obj.rowData[i].transfer_num} </td>
                  <td style="width:20%; text-align: left;" > ${obj.rowData[i].shp_buy_pt} </td>
                  <td style="width:19%; text-align: left;" > ${obj.rowData[i].rec_buy_pt} </td>
                  <td style="width:6%; text-align: right;" > ${obj.rowData[i].trip_weight} </td>
                  <td style="width:6%; text-align: right;" > ${obj.rowData[i].freight_rate} </td>
                  <td style="width:10%; text-align: right;" > ${obj.rowData[i].Strfreight_amt} </td>
                  <td style="width:6%; text-align: right;" > ${obj.rowData[i].dblFuel} </td>
                  <td style="width:6%; text-align: right;" > ${obj.rowData[i].dblLease} </td>
                  <td style="width:7%; text-align: right;" > ${obj.rowData[i].dblMisc} </td>
                  <td style="width:7%; text-align: right;" > ${obj.rowData[i].dblTotal} </td>
                </tr>
              <tr>
                <td> &nbsp; </td>
                <td> &nbsp; </td>
                <td> ${obj.rowData[i].shp_bp_city_st} </td>
                <td> ${obj.rowData[i].rec_bp_city_st} </td>
                <td colspan='3'> Weight Ticket:  ${obj.rowData[i].weightTicket} </td>
                <td colspan='2'> Driver:  ${obj.rowData[i].driver_name} </td>
                <td colspan='2'> Crop Year:  ${obj.rowData[i].crop_year} </td>
              </tr>
              <tr> <td> </br> </td> </tr>
          `
                 
        }
        optionPage += `
        </table>
        
        ${obj.MultipStopTripRow}
        </div>
        <div id="footer">
        <table style="width:100%;" class="footer-table" style="background:red">
          <tr>
            <td style="width: 45%">${obj.FooterVendor}</td>
            <td style="width: 45%">${obj.FooterInvoice}</td>
            <td style="width: 10%">Page: ${Count} </td>
          </tr>
        </table>
        </div>
        </div>
        `
       
    return optionPage + `</html>`
  }
  // Controls Events
  const onbtnPrintClick = async () => {
    let code = '';
    let reportTitle = getData(thisObj, 'ReportTitle');
    if (reportTitle === 'Settlement') {
      code = await GetHtmlCodeForFASettlement(58, 100, '<br/>');
    } else {
      code = await GetHtmlCode(75, 100, '<br/>');
    }
    let type = 'text/html';
    let fileName = "PDF";

    let dataUrl1 = window.URL.createObjectURL(new Blob([code], { type }));
    let pdfWindow = window.open(dataUrl1, '', 'width=1000,height=850,scrollbars=1,menuBar=1', fileName);
    pdfWindow.focus();
    pdfWindow.addEventListener('beforeprint', event => { });
    pdfWindow.print();
    pdfWindow.addEventListener('afterprint', (event) => {
    pdfWindow.close() //Commented this line as PDF window was getting closed before print operation
    })
  }
  thisObj.onbtnPrintClick = onbtnPrintClick;

  const onbtnCloseClick = () => {
    document.getElementById("StockTransfer_ReportPreviewTransferPopUp").childNodes[0].click();
  };
  thisObj.onbtnCloseClick = onbtnCloseClick;

  // Common Function
  const getBlobURL = (code, type) => {
    const blob = new Blob([code], { type });
    return URL.createObjectURL(blob)
  }

  function ConvertDateToMMDDYYYY(dateObj) {
    let date = null;
    if (dateObj !== null) {
      date = dateObj;
      let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
      let day = date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate();

      let dateFormat = month + '/' + day + '/' + date.getFullYear();
      return dateFormat;
    } else {
      return '';
    }
  }

  // Initialize Form and Design
  initForm(thisObj);
  let validationSchema = object(_kaledo.validationSchema);
  let initialValues = _kaledo.initialValues;
  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => {
        thisObj.setFieldValue = setFieldValue = setFieldValue;
        thisObj.values = values = values;
        thisObj.handleChange = handleChange = handleChange;
        thisObj.handleBlur = handleBlur = handleBlur;
        thisObj.isValid = isValid = isValid;
        thisObj.errors = errors = errors;
        return (
          <div
            className={
              _kaledo.guiModuleName + "-" + state.windowName + " col-lg-12" + " centerWidth"
            }
            data-testid={state.windowName}
            ref={screenRef}
          >
            <Modal
              show={state.showPopup}
              onHide={vlaues => {
                handleModalHide(thisObj, values);
              }}
              data={dataCopyObj.dataToCopy}
              component={state.popupComponent}
            />
            <ContractManagement_header headerData={state.headerData} />
            <h1 className="pageHeader">{state.Label}</h1>

            <Form noValidate className="row">
              <iframe src={state.renderUrl}
                id="docFrame"
                width="97.5%"
                height="1000px"
                style={{ background: "White", marginLeft : "15px" }}>
              </iframe>
              <GroupBoxWidget
                conf={state.grpbxPreview}
                screenConf={state}
              ></GroupBoxWidget>
              <GroupBoxWidget conf={state.grpbxActions} screenConf={state}>
                <ButtonWidget
                  conf={state.btnPrintSetup}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
              <ButtonWidget
               conf={state.btnPrint}
               //PRB0053955 - Stock transfer printing issue fixes
               Disabled={isLoading} 
               screenConf={state}
               onClick={isLoading ? null : thisObj.onbtnPrintClick}
               className={`button-widget ${isLoading ? 'disabled' : ''}`}
                 ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnSaveAsPDF}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <ButtonWidget
                  conf={state.btnClose}
                  screenConf={state}
                  onClick={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                ></ButtonWidget>
                <CheckboxGroupWidget
                  onChange={event =>
                    invokeEventHandler(event, handleChange, handleBlur, thisObj)
                  }
                  onBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  conf={state.chkboxSelectCopies}
                  screenConf={state}
                ></CheckboxGroupWidget>
              </GroupBoxWidget>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default withRouter(StockTransfer_ReportPreviewTransfer);
